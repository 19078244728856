/** @format */

import React from 'react';
import ToolCard from './ToolCard';
import AnimalsCard from './AnimalsCard';
import MembershipCard from './MembershipCard';
import CropsCard from './CropsCard';

export default function CardController({
    data,
    storeMining,
    userEnergy,
    gold
}) {
    if (!!data.schema_name && data.type === 'Gold')
        return (
            <ToolCard
                data={data}
                storeMining={storeMining[0] + 1}
                userEnergy={userEnergy}
                gold={gold}
            />
        );
    if (!!data.schema_name && data.type === 'Food')
        return (
            <ToolCard
                data={data}
                storeMining={storeMining[1] + 1}
                userEnergy={userEnergy}
                gold={gold}
            />
        );
    if (!!data.schema_name && data.type === 'Wood')
        return (
            <ToolCard
                data={data}
                storeMining={storeMining[2] + 1}
                userEnergy={userEnergy}
                gold={gold}
            />
        );
    if (data.gender >= 0)
        return <AnimalsCard data={data} userEnergy={userEnergy} gold={gold} />;
    if (!!data.miss_claim_limit)
        return <CropsCard data={data} userEnergy={userEnergy} gold={gold} />;
    if (!!data.saved_claims)
        return (
            <MembershipCard data={data} userEnergy={userEnergy} gold={gold} />
        );
    return null;
}
