/** @format */

import React from 'react';
import './index.scss';
import GameCard from '../../components/GameCard/GameCard';
import { useSelector } from 'react-redux';

import EmtyTab from '../../components/EmptyTab';
import { getCanBurn } from '../../redux/slice/FoodsSlicer';

export default function Chest() {
    const usingItems = useSelector((state) => state.tools.usingItems);
    const usingBadges = useSelector((state) => state.badge.usingBadges);
    const toolChest = useSelector((state) => state.atomic.tools);
    const ticketsChest = useSelector((state) => state.atomic.tickets);
    const badgeChest = useSelector((state) => state.atomic.memberships);
    const animalsChest = useSelector((state) => state.atomic.farmanimals);
    const buildingChest = useSelector((state) => state.atomic.farmbuilding);
    const plantsChest = useSelector((state) => state.atomic.plants);
    const foodsChest = useSelector((state) => state.atomic.foods);
    const packsChest = useSelector((state) => state.atomic.packs);
    const refundChest = useSelector((state) => state.atomic.refundChest);
    const coinConfig = useSelector((state) => state.coin.coinConfig);
    const gold = useSelector((state) => state.user.balances.gold);
    // const plantsUsing = useSelector((state) => state.plants.plantsUsing);
    // const cowUsing = useSelector((state) => state.animals.cowUsing);
    // const chickenUsing = useSelector((state) => state.animals.chickenUsing);
    const canBurn = useSelector((state) => getCanBurn(state.foods));
    const chestItems = usingBadges
        ?.concat(usingItems)
        ?.concat(ticketsChest)
        ?.concat(refundChest)
        ?.concat(plantsChest)
        ?.concat(badgeChest)
        ?.concat(animalsChest)
        ?.concat(buildingChest)
        ?.concat(foodsChest)
        ?.concat(coinConfig)
        ?.concat(toolChest)
        ?.concat(packsChest);
    // ?.concat(cowUsing)
    // ?.concat(chickenUsing)
    // ?.concat(plantsUsing)
    // ?.concat(cowUsing)
    // ?.concat(chickenUsing)
    const isPlayable = chestItems.length;

    return isPlayable ? (
        <>
            <section className='chest-container'>
                <div className='chest-content'>
                    <div className='chest-card-list'>
                        {chestItems.map(
                            (card, index) =>
                                card.asset_id && (
                                    <GameCard
                                        card={card}
                                        key={index}
                                        gold={gold}
                                        canBurn={canBurn}
                                    />
                                )
                        )}
                    </div>
                </div>
            </section>
            {/* <div className="wapper"></div> */}
        </>
    ) : (
        // <div className="chest-container"></div>
        <EmtyTab />
    );
}
