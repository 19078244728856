/** @format */

import React from 'react';
import { getPicture } from '../../utils';

import './card.scss';

export default function Card({ img, template_name }) {
    return (
        <div className='card-section'>
            <div className='card-img' style={{ width: '70%' }}>
                <img
                    src={getPicture(img)}
                    onError={(e) => {
                        e.target.onerror = null; // prevents looping
                        e.currentTarget.src = getPicture(img);
                    }}
                    alt={template_name}
                    className='card-img--item'
                />
            </div>
        </div>
    );
}
