/** @format */

import React, { useEffect, useState } from 'react';
// import './resourceBar.scss'

import RecoverModal from '../../components/Modal/RecoverModal';
import useModal from '../../components/Modal/useModal';
import { useSpring, animated } from 'react-spring';
import useWindowSize from '../../utils/useWindowSIze';
const min = (a, b) => {
	return a > b ? b : a;
};
export default function ResourceBar(props) {
	const { isShowing, toggle } = useModal();

	const [amount, setAmount] = useState(0);
	useEffect(() => {
		setAmount(parseFloat(props?.amount || 0).toFixed(5));
	}, [props?.amount]);

	const [energy, setEnergy] = useState(0);
	useEffect(() => {
		setEnergy(parseInt(props.energy || 0));
	}, [props?.energy]);
	// eslint-disable-next-line
	const [viewWidth, viewHeight] = useWindowSize();

	// const width = isMobile ? 70 : 184
	const props3 = useSpring({
		width:
			((props.energy || 1) / (props.max_energy || 1)) *
			min(viewWidth / 144, viewHeight / 90) *
			19,
		backgroundColor: '#F1D900',
		config: { duration: 1000 },
	});
	//  184
	const calcFontSize = () => {
		const length = amount?.toString().length || energy?.toString().length;
		if (length < 4) return 2.2;
		if (length < 8) return 1.8;
		else return 1.6;
	};
	const props2 = useSpring({
		number:
			props.name === 'energy'
				? parseInt(energy)
				: parseFloat(amount).toFixed(4),
		config: {
			duration: 500,
			reset: false,
		},
	});
	return (
		<div className='resource__group'>
			<i className='resource-icon'>
				<img
					src={props.icon}
					alt='Resource Icon'
					className='resource-icon--image '
				/>
			</i>
			{props.name === 'energy' ? (
				<animated.div className='fill-yellow' style={props3} />
			):<></>}

			<animated.div
				className='resource-number'
				style={{ fontSize: calcFontSize() + ' rem' }}>
				<animated.div>
					{props2.number.to((x) =>
						props.name === 'energy'
							? parseInt(x)
							: parseFloat(x).toFixed(5).slice(0, -1)
					)}
				</animated.div>
				{props.name === 'energy' ? ` /${props?.max_energy}`: ""}
			</animated.div>
			{props.name === 'energy' ? (
				<div className='resource-energy'>
					<img
						src='./img/plus.png'
						alt='plus'
						className='resource-energy--plus'
						onClick={toggle}
					/>
				</div>
			):<></>}
			<RecoverModal isShowing={isShowing} hide={toggle} />
		</div>
	);
}
