/** @format */

import React from 'react';
import { getPicture } from '../../utils';

export default function BadgeSatellite({ badge_img, name }) {
    return (
        <div className='badge'>
            <img
                src={getPicture(badge_img)}
                alt={name}
                onError={(e) => {
                    e.target.onerror = null; // prevents looping
                    e.currentTarget.src = getPicture(badge_img);
                }}
                className='badge-img'
            />
        </div>
    );
}
