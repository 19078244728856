/** @format */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { game } from './authSlicer';
import { MAIN_SERVERURL, lockFeature } from '../../config';
import { isRepeatable, sleep, timeout } from '../../utils';

export const getTransaction = createAsyncThunk(
    'foods/getTransaction',
    async ({ transactionId, total }) => {
        let response = {};
        let flag = false;
        let SERVER_URL = [...MAIN_SERVERURL];
        console.log(transactionId);
        for (let count = 0; count < MAIN_SERVERURL.length * 5; count++) {
            await sleep(800);
            try {
                response = await timeout(
                    game.getTransaction(
                        transactionId,
                        SERVER_URL[count % SERVER_URL.length]
                    ),
                    2000,
                    null
                );
                if (response.executed !== true) continue;
                if (response.actions) {
                    let burn = 0;
                    let countTotal = 0;
                    response.actions.forEach((action) => {
                        if (action.act.name === 'logburnrs') {
                            countTotal++;
                            burn += parseFloat(
                                action.act.data?.rewards[0].split(' ')[0]
                            );
                        }
                    });
                    response = { rewards: burn.toFixed(4) + ' GOLD' };

                    if (burn > 0 && countTotal === total) flag = true;
                    else continue;
                }
                if (flag !== false) return response;
            } catch (error) {
                SERVER_URL.splice(count % SERVER_URL.length, 1);
                count--;
                if (SERVER_URL.length === 0)
                    throw new Error(`Cannot display what you got!`);

                continue;
            }
        }
        throw new Error(`Cannot display what you got!`);
    }
);

export const exchangeRewards = createAsyncThunk(
    'foods/exchangeRewards',
    async (data, { getState, rejectWithValue }) => {
        const { card, total } = data;
        console.log(`ExchangeRewards`, card, total);
        const { auth } = getState();
        let isVerify = auth.authSettings.features & lockFeature[3].value;
        const isUnlocked2FA = auth.isUnlocked2FA;
        if (isVerify) {
            if (!isUnlocked2FA)
                return rejectWithValue(
                    'You have to Unlock 2FA to use this Feature!'
                );
        }

        for (let count = 0; count < 3; count++) {
            try {
                const response = await game.authRequiredTransaction(
                    game.verify2fa(isVerify),
                    game.exchangeRewards(card.idList.slice(0, total))
                );
                return response;
            } catch (error) {
                if (isRepeatable(error.message) && count < 2) continue;
                throw error;
            }
        }
    },
    {
        condition: (data, { getState, extra }) => {
            const { foods } = getState();
            const fetchStatus = foods.requests.filter(
                (__template_id) =>
                    __template_id === data.card.template.template_id
            );
            if (fetchStatus.length !== 0) {
                return false;
            }
        }
    }
);

export const getExchangeConf = createAsyncThunk(
    'foods/getExchangeConf',
    async () => {
        const response = await game.getExchangeConf();
        return response;
    }
);

export const getFoodName = (state, template_id) =>
    state.foodsConfig.find((item) =>
        item.template_id === template_id ? item.name : ''
    );
export const getCanBurn = (state, template_id) =>
    state.foodsConfig.map((item) => item.template_id + '');

export const FoodsSlicer = createSlice({
    name: 'foods',
    initialState: {
        foodsConfig: [],
        requests: [],
        status: 'idle',
        response: '',
        error: ''
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTransaction.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getTransaction.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.response = action.payload;
            })
            .addCase(getTransaction.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getExchangeConf.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getExchangeConf.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.foodsConfig = action.payload;
            })
            .addCase(getExchangeConf.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = action.error.message;
            })

            .addCase(exchangeRewards.pending, (state, action) => {
                state.status = 'loading';
                state.requests.push(action.meta.arg.card.template.template_id);
            })
            .addCase(exchangeRewards.fulfilled, (state, action) => {
                state.status = 'loaded';
                const index = state.requests.indexOf(
                    action.meta.arg.card.template.template_id
                );
                state.requests.splice(index, 1);
            })
            .addCase(exchangeRewards.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = action.error.message;

                const index = state.requests.indexOf(
                    action.meta.arg.card.template.template_id
                );
                state.requests.splice(index, 1);
            });
    }
});
export const { reOrderCard } = FoodsSlicer.actions;

export default FoodsSlicer.reducer;
