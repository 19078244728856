/** @format */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../Button';
import {
    removeItem,
    repairItem,
    useItem,
    repairCard
} from '../../redux/slice/ToolsSlicer';
import { openPack } from '../../redux/slice/PackSlicer';
import { toggleModal, setErrorMessage } from '../../redux/slice/modalSlice';
import {
    atomicUpdate,
    cancelLoading,
    setBackgroundUpdate,
    setUpdate
} from '../../redux/slice/GameSlicer';
import { mbsUnstake } from '../../redux/slice/BadgeSlicer';
import { updateRepairMoney } from '../../redux/slice/userSlicer';
import { setFlash } from '../../redux/slice/FlashSlicer';
import CountDown from '../../common/CountDown/CountDown';
import getErrorMessages from '../../utils/getErrorMessages';
import { popAssets, getRefund } from '../../redux/slice/AtomicSlicer';
import ExchangeModal from '../Modal/ExchangeModal';
import { getPicture } from '../../utils';

const netURL = 'https://neftyblocks.com/c/farmersworld/packs';

export default function GameCard({ card, gold, canBurn }) {
    const [isModal, setModal] = useState(false);

    const dispatch = useDispatch();
    const lost =
        parseInt(card.durability || 0) - parseInt(card.current_durability || 0);
    const isRepairable = lost !== 0 && lost <= gold * 5 ? true : 'disabled';
    const [isUnstake, setIsUnstake] = useState('disabled');

    const isCountdown =
        card.next_availability < Date.now() / 1000 || 'disabled';
    const isRemovable =
        isUnstake !== 'disabled' &&
        isCountdown !== 'disabled' &&
        isRepairable !== true
            ? true
            : 'disabled';
    useEffect(() => {
        setIsUnstake(
            card.unstaking_time ||
                card.next_availability < Date.now() / 1000 ||
                'disabled'
        );
        return () => {
            setIsUnstake('disabled');
        };
    }, [card.unstaking_time, card.next_availability]);

    const HandleRepair = async () => {
        try {
            dispatch(cancelLoading(false));

            if (isRepairable !== 'disabled') {
                const id = card.asset_id;
                const data = { id, lost };
                const resultAction = await dispatch(repairItem(data)).unwrap();
                if (resultAction?.transaction_id !== null) {
                    const flash_id = Date.now();
                    const flashMessage = {
                        id: flash_id,
                        content: `Repairing your ${card.template_name}`,
                        timeout: 5000
                    };
                    dispatch(setFlash(flashMessage));
                    dispatch(updateRepairMoney(parseFloat(lost / 5)));
                    dispatch(repairCard(card.asset_id));
                    dispatch(setBackgroundUpdate(true));
                }
            } else {
                if (lost / 5 <= gold)
                    dispatch(setErrorMessage('This tool is full Durability!'));
                else
                    dispatch(
                        setErrorMessage(
                            `We need ${lost / 5} golds to repair sir!`
                        )
                    );
                dispatch(toggleModal(true));
            }
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
            dispatch(setBackgroundUpdate(true));
        }
    };
    const HandleExchange = () => {
        setModal(true);
    };

    const HandleWear = async () => {
        try {
            dispatch(cancelLoading(false));
            const resultAction = await dispatch(
                useItem({
                    asset_id: card.idList[0]
                })
            ).unwrap();
            dispatch(popAssets({ card: card, total: 1 }));
            if (resultAction?.transaction_id) {
                const flash_id = Date.now();
                const flashMessage = {
                    id: flash_id,
                    content: `Wearing your ${
                        card.data.name || card.data.template_name
                    }`,
                    timeout: 5000
                };
                dispatch(setFlash(flashMessage));
            }
            dispatch(setBackgroundUpdate(true));
            await dispatch(atomicUpdate()).unwrap();
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
            dispatch(setBackgroundUpdate(true));
            await dispatch(atomicUpdate()).unwrap();
            throw error;
        }
    };
    const HandleRefund = async () => {
        try {
            dispatch(cancelLoading(false));
            const resultAction = await dispatch(getRefund()).unwrap();
            const flash_id = Date.now();
            const flashMessage = {
                id: flash_id,
                content: `Refunding your ${
                    card.data.name || card.data.template_name
                }`,
                timeout: 5000
            };
            dispatch(setFlash(flashMessage));
            if (resultAction?.transaction_id) {
                dispatch(
                    setErrorMessage(
                        'You have been refunded successfully. Thank you for your cooperation!'
                    )
                );
                dispatch(toggleModal(true));
            }
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
        } finally {
            dispatch(setUpdate(true));
        }
    };
    const HandleRemove = async () => {
        try {
            dispatch(cancelLoading(false));

            if (isRemovable !== 'disabled') {
                let resultAction = {};
                if (card.name)
                    resultAction = await dispatch(
                        mbsUnstake(card.asset_id)
                    ).unwrap();
                else
                    resultAction = await dispatch(
                        removeItem(card.asset_id)
                    ).unwrap();
                if (resultAction?.transaction_id !== null) {
                    const flash_id = Date.now();
                    const flashMessage = {
                        id: flash_id,
                        content: `Removing your ${
                            card.template_name || card.name
                        }!`,
                        timeout: 5000
                    };
                    dispatch(setFlash(flashMessage));
                }
                dispatch(setBackgroundUpdate(true));
                await dispatch(atomicUpdate()).unwrap();
            } else {
                if (isRepairable !== 'disabled')
                    dispatch(
                        setErrorMessage(`You must repair your card first`)
                    );
                else
                    dispatch(
                        setErrorMessage(
                            `Can not remove this card due to countdown.`
                        )
                    );
                dispatch(toggleModal(true));
            }
        } catch (error) {
            dispatch(setUpdate(true));
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
        }
    };

    const handleOpen = async () => {
        try {
            if (card.asset_id !== undefined) {
                dispatch(cancelLoading(false));
                const resultAction = await dispatch(
                    openPack(card.idList[0])
                ).unwrap();
                if (resultAction?.transaction_id !== null) {
                    dispatch(popAssets({ card: card, total: 1 }));
                    const flash_id = Date.now();
                    const flashMessage = {
                        id: flash_id,
                        content: 'A few seconds and resources will be add !!!',
                        timeout: 5000
                    };
                    dispatch(setFlash(flashMessage));
                    let receivedOpenedPack = 0;
                    resultAction?.processed.action_traces.forEach((action) => {
                        action.inline_traces.forEach((inline_trace) => {
                            receivedOpenedPack =
                                inline_trace.act.data?.points?.join(' and ');
                        });
                    });
                    dispatch(
                        setErrorMessage(
                            `You are about to get ` + receivedOpenedPack
                        )
                    );
                    dispatch(toggleModal(true));
                    dispatch(setBackgroundUpdate(true));
                }
            } else {
                dispatch(setErrorMessage('Please try again later'));
                dispatch(toggleModal(true));
                dispatch(setBackgroundUpdate(true));
            }
        } catch (error) {
            dispatch(setBackgroundUpdate(true));
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
        }
    };
    const HandleOpenNefty = () => {
        window.open(netURL, '_blank').focus();
        dispatch(setErrorMessage('Only Token Pack can be opened in game!'));
        dispatch(toggleModal(true));
    };
    //
    return (
        <div className='card-container'>
            <div className='card-wrapper'>
                {(card.data?.img || card?.img) && (
                    <img
                        src={getPicture(card.data?.img || card?.img)}
                        onError={(e) => {
                            e.target.onerror = null; // prevents looping
                            e.currentTarget.src = getPicture(
                                card.data?.img || card?.img
                            );
                        }}
                        alt={card.data?.name || card.template_name}
                        className='card-container__image'
                        width='135'
                        height='190'
                    />
                )}
            </div>
            {card?.total && (
                <div className={'card-container--amount coin'}>
                    {/* + card.schema?.schema_name */}
                    {card?.total} {card.data?.name}
                </div>
            )}
            {card?.unstaking_time && (
                <CountDown
                    next_availability={card.unstaking_time}
                    handleFinish={setIsUnstake}
                />
            )}
            {card?.current_durability >= 0 && (
                <div className='card-container--number'>
                    {card?.current_durability}/{card?.durability}
                </div>
            )}
            <div className='card-group__button '>
                {card?.current_durability >= 0 && (
                    <Button
                        text='Repair'
                        isDisabled={isRepairable}
                        handleClick={HandleRepair}
                        atr='short small'
                    />
                )}

                {card?.charged_time >= 0 && (
                    <Button
                        text='Remove'
                        isDisabled={isRemovable}
                        handleClick={HandleRemove}
                        atr='short small'
                    />
                )}

                {card.isOpenable && (
                    <Button
                        text={'Open'}
                        handleClick={handleOpen}
                        atr='long small'
                    />
                )}
                {card.isOpenLink && (
                    <Button
                        text={'Nefty'}
                        handleClick={HandleOpenNefty}
                        atr='long small'
                    />
                )}
                {card.isRefundable && (
                    <Button
                        text={'Refund'}
                        handleClick={HandleRefund}
                        atr='long small'
                    />
                )}
                {card.isWearable && (
                    <Button
                        text='Wear'
                        handleClick={HandleWear}
                        atr='short small'
                    />
                )}
                {canBurn.includes(card.template?.template_id) && (
                    <Button
                        text='Exchange'
                        handleClick={HandleExchange}
                        atr='short small'
                    />
                )}
            </div>
            {isModal && (
                <ExchangeModal
                    card={card}
                    handleCancel={() => setModal(false)}
                    max={card.idList.length}
                />
            )}
        </div>
    );
}
