/** @format */

// import "./index.scss"

import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import Button from '../Button';

import energyicon from '../../assets/img/energy-icon.png';
import minusicon from '../../assets/img/minus.png';
import plusicon from '../../assets/img/plus.png';
import plainMeat from '../../assets/img/plain-meat.png';
import plainEnergy from '../../assets/img/plain-energy.png';
import closeButton from '../../assets/img/close-button.png';

import { useSelector, useDispatch } from 'react-redux';
import {
    cancelLoading,
    setBackgroundUpdate
} from '../../redux/slice/GameSlicer';
import { toggleModal, setErrorMessage } from '../../redux/slice/modalSlice';
import {
    recover,
    UpdateHealth,
    updateBalance
} from '../../redux/slice/userSlicer';
import InformModal from './InformModal';
import { setFlash } from '../../redux/slice/FlashSlicer';
import getErrorMessages from '../../utils/getErrorMessages';
const ExchangeModal = ({ isShowing, hide }) => {
    const [changeEnergy, setChangeEnergy] = useState(0);
    const user = useSelector((state) => state.user.info);
    const food = useSelector((state) => state.user.balances.food);
    const [max, setMax] = useState(0);
    useEffect(() => {
        if ((user?.max_energy - user?.energy) / 5 <= food)
            setMax(user?.max_energy - user?.energy);
        else setMax(food * 5);
    }, [user, food]);
    const dispatch = useDispatch();
    const HandleExchange = async () => {
        try {
            dispatch(cancelLoading(false));
            if (isExchangable !== 'disabled') {
                const foodConsumed = changeEnergy / 5;
                const resultAction = await dispatch(
                    recover(foodConsumed)
                ).unwrap();
                if (resultAction?.transaction_id !== null) {
                    const flash_id = Date.now();
                    const flashMessage = {
                        id: flash_id,
                        content: `Eating ${foodConsumed} food`,
                        timeout: 1000
                    };
                    dispatch(setFlash(flashMessage));
                    dispatch(
                        UpdateHealth({ type: 'plus', value: changeEnergy })
                    );
                    dispatch(updateBalance(`-${foodConsumed} food`));
                    dispatch(setBackgroundUpdate(true));
                }
            } else {
                dispatch(toggleModal(true));
                dispatch(
                    setErrorMessage('You can not exchange stuff like this!')
                );
            }
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
            dispatch(setBackgroundUpdate(true));
        } finally {
            setChangeEnergy(0);
            hide();
        }
    };
    const HandleChange = (e) => {
        if (e.target.value <= 0) setChangeEnergy(0);
        else if (e.target.value >= max) setChangeEnergy(max);
        else setChangeEnergy(e.target.value);
        e.preventDefault();
    };
    const refOutside = useRef(null);
    const handleClickOutside = (event) => {
        if (refOutside.current && !refOutside.current.contains(event.target)) {
            hide();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });
    const isExchangable = changeEnergy !== 0 ? '' : 'disabled';

    return isShowing
        ? ReactDOM.createPortal(
              <React.Fragment>
                  <InformModal />
                  <div
                      className='modal-wrapper'
                      aria-modal
                      aria-hidden
                      tabIndex={-1}
                      role='dialog'>
                      <div className='modal exchange-modal' ref={refOutside}>
                          <div className='modal-header'>
                              <img
                                  src={energyicon}
                                  alt='Energy Icon'
                                  style={{ width: '5rem', height: '5rem' }}
                              />
                              <img
                                  src={closeButton}
                                  alt='Close'
                                  className='image-button close-modal'
                                  onClick={hide}
                              />
                          </div>
                          <div className='modal-body'>
                              <img
                                  src={minusicon}
                                  alt='Minus Icon'
                                  value={changeEnergy}
                                  onClick={() =>
                                      setChangeEnergy(
                                          changeEnergy <= 5
                                              ? 0
                                              : changeEnergy - 5
                                      )
                                  }
                                  className='image-button'
                              />
                              <input
                                  type='number'
                                  min={0}
                                  max={max}
                                  className='modal-input'
                                  value={changeEnergy}
                                  onChange={(e) => HandleChange(e)}
                              />

                              <img
                                  src={plusicon}
                                  alt='Plus Icon'
                                  onClick={() =>
                                      setChangeEnergy(
                                          changeEnergy + 5 <= max
                                              ? changeEnergy + 5
                                              : max
                                      )
                                  }
                                  className='image-button'
                              />
                          </div>
                          <div className='modal-close-button tooltip'>
                              <Button
                                  type='button'
                                  data-dismiss='modal'
                                  text='Exchange'
                                  atr='long'
                                  wrapperClassname='full-width'
                                  isDisabled={isExchangable}
                                  handleClick={HandleExchange}
                              />
                              {isExchangable === 'disabled' && (
                                  <span className='tooltiptext tooltip-bottom'>
                                      <i className='arrow-up'></i>Enter a Valid
                                      Amount
                                  </span>
                              )}
                          </div>
                          <div className='modal-footer'>
                              <div className='modal-footer-text'>
                                  {changeEnergy / 5 || 1}
                              </div>
                              <img
                                  src={plainMeat}
                                  alt='Meat Icon'
                                  className='exchange-icon'
                              />
                              <div style={{ padding: '0 2px' }}>=</div>
                              <div className='modal-footer-text'>
                                  {changeEnergy || 5}
                              </div>

                              <img
                                  src={plainEnergy}
                                  alt='Energy Icon'
                                  className='exchange-icon'
                              />
                          </div>
                      </div>
                  </div>
              </React.Fragment>,
              document.body
          )
        : null;
};
export default ExchangeModal;
