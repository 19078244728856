/** @format */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../../components/Button';
import {
    mineItem,
    repairItem,
    repairCard,
    UpdateTimestamp,
    getTransaction
} from '../../redux/slice/ToolsSlicer';
import {
    cancelLoading,
    setBackgroundUpdate
} from '../../redux/slice/GameSlicer';
import { toggleModal, setErrorMessage } from '../../redux/slice/modalSlice';
import { updateRepairMoney } from '../../redux/slice/userSlicer';
import { useSpring, animated } from 'react-spring';

import { deleteFlash, setFlash } from '../../redux/slice/FlashSlicer';
import getErrorMessages from '../../utils/getErrorMessages';
import CountDownTool from '../../common/CountDown/CountDownTool';
import useWindowSize from '../../utils/useWindowSIze';
import { getPicture } from '../../utils';

const mapTypeIcon = {
    Wood: './img/WOOD-type-icon.svg',
    Gold: './img/GOLD-type-icon.svg',
    Food: './img/FOOD-type-icon.svg'
};

const min = (a, b) => {
    return a > b ? b : a;
};

export default function ToolCard({ storeMining, data, gold, userEnergy }) {
    const dispatch = useDispatch();

    const [store, setStore] = useState(0);

    const [storeStatus, setStoreStatus] = useState(false);

    const storeMiningMaximum = storeMining;

    const [isCountDown, setCountdown] = useState('disabled');

    const lost =
        parseFloat(data.durability) - parseFloat(data.current_durability);

    const isRepairable = lost !== 0 && lost / 5 <= gold ? '' : 'disabled';

    const isDurable =
        data.current_durability >= data.durability_consumed * store;
    const isEnergy = userEnergy >= data.energy_consumed * store;
    const mineText = isEnergy
        ? isCountDown === true
            ? isDurable
                ? 'Mine'
                : 'Damaged'
            : 'Countdown'
        : 'No Energy';
    const isMinable = mineText === 'Mine' ? '' : 'disabled';
    // eslint-disable-next-line
    const [viewWidth, viewHeight] = useWindowSize();

    const dubral = useSpring({
        width:
            ((data.current_durability || 0) / (data.durability || 1)) *
            min(viewWidth / 144, viewHeight / 90) *
            16.5,
        backgroundColor: '#B06A38',
        config: { duration: 1000 }
    });

    useEffect(() => {
        const storeMining = Math.floor(
            (Math.floor(Date.now()) / 1000 - data.next_availability) /
                data.charged_time
        );
        if (storeMining < 0) {
            setStore(0);
        } else {
            setStore(
                storeMining < storeMiningMaximum
                    ? storeMining + 1
                    : storeMiningMaximum
            );
        }
        setCountdown(data.next_availability * 1000 < Date.now() || 'disabled');
        return () => {
            setCountdown(true);
            setStoreStatus(false);
        };
    }, [
        data.next_availability,
        isCountDown,
        storeStatus,
        data.charged_time,
        storeMiningMaximum
    ]);

    const HandleMine = async () => {
        try {
            dispatch(cancelLoading(false));

            if (isMinable !== 'disabled') {
                const flash_id = Date.now();
                const resultAction = await dispatch(
                    mineItem(data.asset_id)
                ).unwrap();

                const flashMessage = {
                    id: flash_id,
                    content: `Using your ${data.template_name} to mine`,
                    timeout: 30000
                };
                dispatch(setFlash(flashMessage));
                try {
                    if (!!resultAction.transaction_id) {
                        dispatch(UpdateTimestamp());
                        const response = await dispatch(
                            getTransaction(resultAction.transaction_id)
                        ).unwrap();
                        let bonus =
                            response.bonus?.act?.data?.bonus_rewards?.join(
                                ', '
                            ) || 0;
                        let claim =
                            response.claim?.act?.data?.rewards?.join(', ');
                        if (response) {
                            const inform =
                                "You've just mined " +
                                claim +
                                ' and Membership Bonus: ' +
                                (bonus || 0);
                            dispatch(setErrorMessage(inform));
                            dispatch(toggleModal(true));
                            dispatch(setBackgroundUpdate(true));
                            dispatch(deleteFlash(flash_id));
                        }
                    }
                } catch (error) {
                    dispatch(setErrorMessage('Your got your rewards!'));
                    dispatch(deleteFlash(flash_id));
                    dispatch(setBackgroundUpdate(true));
                }
            } else {
                if (isCountDown !== true)
                    dispatch(
                        setErrorMessage(`${data.template_name} is being used!`)
                    );
                else if (data.current_durability >= data.durability_consumed)
                    dispatch(
                        setErrorMessage(
                            `Your ${data.template_name} is too low to continue`
                        )
                    );
                else
                    dispatch(
                        setErrorMessage(
                            "You don't have enough energy to mine use this tool."
                        )
                    );

                dispatch(toggleModal(true));
            }
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
            dispatch(setBackgroundUpdate(true));
        }
    };
    const HandleRepair = async () => {
        const flash_id = Date.now();
        try {
            dispatch(cancelLoading(false));
            if (isRepairable !== 'disabled') {
                const flashMessage = {
                    id: flash_id,
                    content: `Repairing your ${data.template_name}`,
                    timeout: 5000
                };
                dispatch(setFlash(flashMessage));
                const id = data.asset_id;
                const repairData = { id, lost };
                const resultAction = await dispatch(
                    repairItem(repairData)
                ).unwrap();
                if (resultAction?.transaction_id !== null) {
                    dispatch(updateRepairMoney(parseFloat(lost / 5)));
                    dispatch(repairCard(id));
                }
            }
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
        } finally {
            dispatch(setBackgroundUpdate(true));
            dispatch(deleteFlash(flash_id));
        }
    };

    return (
        <div className='home__card-container'>
            <div className='card-section'>
                <div className='card-img'>
                    <img
                        src={getPicture(data.img)}
                        onError={(e) => {
                            e.target.onerror = null; // prevents looping
                            e.currentTarget.src = getPicture(data.img);
                        }}
                        alt='card'
                        className='card-img--item'
                    />
                </div>

                <div className='card-number'>
                    <animated.div className='fill' style={dubral} />
                    <animated.div className='content'>
                        {data.current_durability || 0}/ {data.durability || 0}
                    </animated.div>
                </div>
            </div>
            <div className='info-section'>
                <div className='info-text__section'>
                    <div className='info-title'>
                        <div
                            className='info-type-icon'
                            style={{
                                backgroundImage: `url(${
                                    mapTypeIcon[data.type]
                                })`
                            }}></div>
                        <div className='info-title-name'>
                            {data.template_name}
                        </div>
                        <div className='info-title-level'>
                            {/* LV: {data.level || null} */}
                            {store}/{storeMiningMaximum}
                        </div>
                    </div>
                    <div className='info-content'>
                        <div className='info-label'>
                            Rarity:
                            <div className='info-description'>
                                {data.rarity || null}
                            </div>
                        </div>

                        <div className='info-label'>
                            Reward Rate:
                            <div className='info-description'>
                                {/* || data.rewards_rate */}
                                {data.reward}
                            </div>
                        </div>

                        <div className='info-label'>
                            Charge Time:
                            <div className='info-description'>
                                {data.charged_time / 60 > 60
                                    ? data.charged_time / 60 / 60 + ' hours'
                                    : data.charged_time / 60 + ' mins' || null}
                            </div>
                        </div>
                        <div className='info-label'>
                            Energy Consumed:
                            <div className='info-description'>
                                {data.energy_consumed || 0}
                            </div>
                        </div>
                        <div className='info-label'>
                            Durability Consumed:
                            <div className='info-description'>
                                {data.durability_consumed || null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='info-time'>
                    <CountDownTool
                        next_availability={
                            data.next_availability +
                            data.charged_time *
                                (store < storeMiningMaximum && store)
                        }
                        handleFinish={setCountdown}
                        charged_time={data.charged_time}
                        storeMiningMaximum={storeMiningMaximum}
                        handleStoreMining={setStoreStatus}
                    />
                </div>
                <div className='home-card-button__group'>
                    <div className='tooltip home-card-button--item'>
                        <Button
                            className='repair-btn'
                            text={mineText}
                            atr='semi-short'
                            isDisabled={isMinable}
                            wrapperClassname='set-height'
                            handleClick={HandleMine}
                        />
                        {!isDurable && (
                            <span className='tooltiptext tooltip-bottom'>
                                <i className='arrow-up'></i>Need{' '}
                                {data.durability_consumed * store -
                                    data.current_durability}{' '}
                                durability consumed to mine.{' '}
                            </span>
                        )}
                        {!isEnergy && (
                            <span className='tooltiptext tooltip-bottom'>
                                <i className='arrow-up'></i>Need{' '}
                                {data.energy_consumed * store - userEnergy}{' '}
                                energy to mine.{' '}
                            </span>
                        )}
                    </div>
                    <div className='tooltip home-card-button--item'>
                        <Button
                            className='repair-btn'
                            text='Repair'
                            atr='semi-short'
                            isDisabled={isRepairable}
                            wrapperClassname='set-height'
                            handleClick={HandleRepair}
                        />
                        {isRepairable !== 'disabled' && (
                            <span className='tooltiptext tooltip-bottom'>
                                <i className='arrow-up'></i>You need {lost / 5}{' '}
                                Golds to repair
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
