/** @format */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTokens, getConfigs } from '../../tabs/exchange/exchangeSlice';
import { getPlayerInfo } from './userSlicer';
import { getUsingItems, getEquipConfigs } from './ToolsSlicer';
import { getBadgeConfig, getBadgeCraft, getUsingBadge } from './BadgeSlicer';
import { getBuildingConfig, getUsingBuilding } from './BuildingSlicer';
import { getAnimalsConf, getUsingAnimals } from './AnimalsSlicer';

import { game, getAuthSettings, getRemove2FARequest } from './authSlicer';
import { getMarketConf } from './MarketSlicer';
import { getUsingPlants, getPlantsConfig } from './PlantsSlicer';
import { getCoin, getStakedCoin, getTotalCoin } from './CoinSlicer';
import { getExchangeConf } from './FoodsSlicer';
import { getBreedingConf, getBreedings } from './BreedingSlicer';
import { getAtomicChest, getRefundItem } from './AtomicSlicer';
import { isRepeatable } from '../../utils';

export const thunkUpdater = createAsyncThunk(
    'game/thunkUpdater',
    async (_, thunkApi) => {
        await thunkApi.dispatch(getRefundItem());
        await Promise.all([
            // thunkApi.dispatch(getExchangeTransactions()),
            thunkApi.dispatch(getEquipConfigs()),
            // thunkApi.dispatch(getSchemas()),
            thunkApi.dispatch(getStakedCoin()),
            thunkApi.dispatch(getAtomicChest()),
            thunkApi.dispatch(getExchangeConf()),
            thunkApi.dispatch(getBreedingConf()),
            thunkApi.dispatch(getBuildingConfig()),
            thunkApi.dispatch(getBadgeConfig()),
            thunkApi.dispatch(getAnimalsConf()),
            thunkApi.dispatch(getConfigs()),
            thunkApi.dispatch(getMarketConf()),
            thunkApi.dispatch(getPlantsConfig()),
            thunkApi.dispatch(getBadgeCraft()),
            thunkApi.dispatch(getWaxAccount()),
            thunkApi.dispatch(getAccountToken()),
            thunkApi.dispatch(getPlayerInfo()),
            thunkApi.dispatch(getCoin()),
            thunkApi.dispatch(getAuthSettings()),
            thunkApi.dispatch(mbsGetUnclaimedAsset()),
            thunkApi.dispatch(getRemove2FARequest())
        ]);
        await Promise.all([
            thunkApi.dispatch(getUsingBadge()),
            thunkApi.dispatch(getTokens()),
            thunkApi.dispatch(getUsingItems()),
            thunkApi.dispatch(getUsingBuilding()),
            thunkApi.dispatch(getUsingAnimals()),
            thunkApi.dispatch(getTotalCoin()),
            thunkApi.dispatch(getUsingPlants()),
            thunkApi.dispatch(getBreedings())
        ]);
    }
);
export const backgroundUpdate = createAsyncThunk(
    'game/backgroundUpdate',
    async (_, thunkApi) => {
        return await Promise.all([
            thunkApi.dispatch(getPlayerInfo()),
            thunkApi.dispatch(getUsingItems()),
            thunkApi.dispatch(getUsingBadge()),
            thunkApi.dispatch(getUsingBuilding()),
            thunkApi.dispatch(getTokens()),
            thunkApi.dispatch(getUsingPlants()),
            thunkApi.dispatch(getUsingAnimals()),
            thunkApi.dispatch(getBreedings()),
            thunkApi.dispatch(getAuthSettings())
        ]);
    }
);
export const atomicUpdate = createAsyncThunk(
    'auth/atomicUpdate',
    async (_, thunkApi) => {
        return await Promise.all([
            thunkApi.dispatch(getAtomicChest()),
            thunkApi.dispatch(getPlayerInfo()),
            thunkApi.dispatch(getCoin()),
            thunkApi.dispatch(getTotalCoin())
        ]);
    }
);

export const mbsGetUnclaimedAsset = createAsyncThunk(
    'gae/mbsGetUnclaimedAsset',
    async () => {
        for (let count = 0; count < 3; count++) {
            try {
                const response = await game.mbsGetUnclaimedAsset();
                return response;
            } catch (error) {
                if (isRepeatable(error.message) && count < 2) continue;
                throw error;
            }
        }
    }
);

export const getWaxAccount = createAsyncThunk(
    'game/getWaxAccount',
    async (account) => {
        const response = await game.getWaxAccount(account);
        return response;
    }
);
export const getAccountToken = createAsyncThunk(
    'game/getAccountToken',
    async () => {
        const response = await game.getAccountToken();
        return response;
    }
);

export const getSchemas = createAsyncThunk('game/getSchemas', async () => {
    const response = await game.getSchemas();
    console.log(response);
    return response;
});

export const setAccountToken = createAsyncThunk(
    'game/setAccountToken',
    async (_, { getState }) => {
        const { game: gameState } = getState();

        for (let count = 0; count < 3; count++) {
            try {
                await game.setAccountTokens(gameState.removeToken);
                return true;
            } catch (error) {
                if (isRepeatable(error.message) && count < 2) continue;
                throw error;
            }
        }
    }
);

export const buyCpuNet = createAsyncThunk('game/buyCpuNet', async (data) => {
    for (let count = 0; count < 3; count++) {
        try {
            const response = await game.buyCpuNet(data);
            return response;
        } catch (error) {
            if (isRepeatable(error.message) && count < 2) continue;
            throw error;
        }
    }
});

export const buyRam = createAsyncThunk('game/buyRam', async (data) => {
    for (let count = 0; count < 3; count++) {
        try {
            const response = await game.buyRam(data);
            return response;
        } catch (error) {
            if (isRepeatable(error.message) && count < 2) continue;
            throw error;
        }
    }
});
function filterToken(item) {
    if (item.contract === 'farmerstoken' && item.token.includes('4')) {
        return true;
    }
    return false;
}
function filterOldToken(item) {
    if (item.contract === 'farmerstoken' && item.token.includes('8')) {
        return true;
    }
    return false;
}

export const GameSlicer = createSlice({
    name: 'game',
    initialState: {
        status: 'idle',
        waxAccountInfo: {
            ram_quota: 0,
            net_weight: 0,
            cpu_weight: 0,
            net_limit: {
                used: 0,
                available: 0,
                max: 0
            },
            cpu_limit: {
                used: 0,
                available: 0,
                max: 0
            },
            ram_usage: 3164,
            total_resources: {
                owner: '',
                net_weight: '0.00000000 WAX',
                cpu_weight: '0.00000000 WAX',
                ram_bytes: 0
            }
        },
        selectedMap: 0,
        update: false,
        backgroundStatus: 'idle',
        backgroundUpdateStatus: false,
        isCanceled: false,
        isGameLoaded: false,
        isSetToken: true,
        lackingResource: '',
        removeToken: [],
        lackingValue: '',
        error: '',
        claimAssets: []
    },
    reducers: {
        setUpdate: (state, action) => {
            state.update = action.payload;
        },
        setBackgroundUpdate: (state, action) => {
            state.backgroundUpdateStatus = action.payload;
        },
        cancelLoading: (state, action) => {
            state.isCanceled = action.payload;
        },
        setTokenModal: (state, action) => {
            state.isSetToken = action.payload;
        },
        setLackResource: (state, action) => {
            state.lackingResource = action.payload;
            state.lackingValue = '';
        },
        setLackingValue: (state, action) => {
            state.lackingValue = action.payload;
        },
        setSelectedMap: (state, action) => {
            state.selectedMap = action.payload;
        },
        setClearAsset: (state) => {
            state.claimAssets = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunkUpdater.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(thunkUpdater.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isGameLoaded = 'loaded';
                state.update = false;
            })
            .addCase(thunkUpdater.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getSchemas.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getSchemas.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(getSchemas.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(backgroundUpdate.pending, (state, action) => {
                state.backgroundStatus = 'loading';
            })
            .addCase(backgroundUpdate.fulfilled, (state, action) => {
                state.backgroundStatus = 'succeeded';
                state.backgroundUpdateStatus = false;
            })
            .addCase(backgroundUpdate.rejected, (state, action) => {
                state.backgroundStatus = 'failed';
                state.error = action.error.message;
            })
            .addCase(atomicUpdate.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(atomicUpdate.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(atomicUpdate.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(getWaxAccount.pending, (state, action) => {
                state.status = 'pending';
            })
            .addCase(getWaxAccount.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.waxAccountInfo = action.payload;
            })
            .addCase(getWaxAccount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(getAccountToken.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getAccountToken.fulfilled, (state, action) => {
                if (action.payload === true) state.isSetToken = true;
                else {
                    const farmerstoken = action.payload.filter(filterToken);
                    const oldToken = action.payload.filter(filterOldToken);
                    let isSet1 = false;
                    let isSet2 = true;
                    if (oldToken.length >= 1) {
                        let temp = [];
                        oldToken.forEach((token) => {
                            temp.push(token.key);
                        });
                        state.removeToken = temp;
                        isSet1 = true;
                    }
                    if (farmerstoken.length === 3) {
                        isSet2 = false;
                    }
                    if (isSet1 || isSet2) {
                        state.isSetToken = false;
                    } else {
                        state.isSetToken = true;
                    }
                }
                state.status = 'loaded';
            })
            .addCase(getAccountToken.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(mbsGetUnclaimedAsset.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(mbsGetUnclaimedAsset.fulfilled, (state, action) => {
                state.status = 'loaded';
                let temp = [];
                action.payload.forEach((payload) => {
                    temp.push(payload.asset_id);
                });
                state.claimAssets = temp;
            })
            .addCase(mbsGetUnclaimedAsset.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(setAccountToken.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(setAccountToken.fulfilled, (state, action) => {
                state.isSetToken = action.payload;
                state.status = 'loaded';
            })
            .addCase(setAccountToken.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(buyCpuNet.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(buyCpuNet.fulfilled, (state, action) => {
                state.status = 'loaded';
            })
            .addCase(buyCpuNet.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(buyRam.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(buyRam.fulfilled, (state, action) => {
                state.status = 'loaded';
            })
            .addCase(buyRam.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const {
    setUpdate,
    setBackgroundUpdate,
    cancelLoading,
    setTokenModal,
    setLackResource,
    setLackingValue,
    setSelectedMap,
    setClearAsset
} = GameSlicer.actions;

export default GameSlicer.reducer;
