/** @format */

import React, { useState } from 'react';

import WithdrawCoin from './WithdrawCoin';
import WithdrawToken from './WithdrawToken';
import left from '../../assets/img/prev.png';
import right from '../../assets/img/next.png';
export default function WithdrawTab() {
    const [isWithdrawCoin, setWithdrawCoin] = useState(false);

    return (
        <div className='withdrawTab-container'>
            {isWithdrawCoin ? (
                <WithdrawCoin isWithdrawCoin={true} />
            ) : (
                <WithdrawToken />
            )}
            <button
                onClick={() => setWithdrawCoin(!isWithdrawCoin)}
                className={
                    isWithdrawCoin
                        ? 'withdrawTab-nav left'
                        : 'withdrawTab-nav right'
                }>
                {!isWithdrawCoin && 'Coin'}
                <img
                    src={isWithdrawCoin ? left : right}
                    alt='Nav'
                    className='withdraw__nav--img'
                />
                {isWithdrawCoin && 'Token'}
            </button>
        </div>
    );
}
