import { IPFS_SERVER } from '../config';

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}
export const timeout = (prom, time, timeoutVal) =>
    Promise.race([
        prom,
        new Promise((_r, rej) => setTimeout(() => _r(timeoutVal), time))
    ]);

export const raceAll = async (promises, timeoutTime, timeoutVal) => {
    return await Promise.all(
        promises.map((p) => {
            return timeout(p, timeoutTime, timeoutVal);
        })
    );
};

export const getPicture = (hash) => {
    if (
        window[hash] &&
        !window[hash]?.src?.includes('ipfs.eth.aragon.network')
    ) {
        return window[hash].src;
    }
    try {
        const index = getRndInteger(0, IPFS_SERVER.length);
        const newImage = new Image();
        newImage.src = IPFS_SERVER[index] + hash;
        window[hash] = newImage;
        return newImage.src;
    } catch (error) {
        return getPicture(hash);
    }
};

export const isRepeatable = (message) => {
    let bool = false;
    try {
        let temp = JSON.stringify(message);
        bool =
            temp?.includes('undefined') ||
            temp?.includes('billed') ||
            temp?.includes('transaction') ||
            temp?.includes('Failed to fetch') ||
            temp?.includes('Sender ');
    } catch (error) {
        bool =
            message?.includes('undefined') ||
            message?.includes('billed') ||
            message?.includes('transaction') ||
            message?.includes('Failed to fetch');
    }

    return bool;
};
// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'chunk_inefficient', {
    value: function (chunkSize) {
        var array = this;
        return [].concat.apply(
            [],
            array.map(function (elem, i) {
                return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
            })
        );
    }
});
