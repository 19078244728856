import React from 'react';

export default function ExchangeBalance({ resource, image, type }) {
    return (
        <div className='resource-balance-wrapper'>
            <div className='resource-balance-amount'>
                {parseFloat(resource || 0).toFixed(2)}
            </div>
            <img
                src={image}
                alt='Resource Balance Icon'
                className={type && 'resource-balance-icon'}
            />
            {type && <div className='resource-balance-metrics'>{type}</div>}
        </div>
    );
}
