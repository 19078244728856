import React from 'react';
import styles from './CoinBalance.module.scss';
const Coinbalance = ({ amount, img }) => {
    return (
        <span className={styles.text}>
            {amount}
            <img src={img} alt='Coin-logo' />
        </span>
    );
};

export default Coinbalance;
