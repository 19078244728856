/** @format */

import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Button from '../Button';

// import './index.scss'
import { useDispatch } from 'react-redux';
import { toggleModal, setErrorMessage } from '../../redux/slice/modalSlice';
import getErrorMessages from '../../utils/getErrorMessages';
import { setBackgroundUpdate } from '../../redux/slice/GameSlicer';

const ApproveModal = ({ HandleCancel, handleClick, content }) => {
    const dispatch = useDispatch();
    const refOutside = useRef(null);
    const handleClickOutside = (event) => {
        if (refOutside.current && !refOutside.current.contains(event.target)) {
            HandleCancel();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });
    const handleDispatch = async () => {
        try {
            await dispatch(handleClick).unwrap();
            dispatch(setBackgroundUpdate(true));
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
            dispatch(setBackgroundUpdate(true));
        }
    };
    return ReactDOM.createPortal(
        <React.Fragment>
            <div className='modal-wrapper' tabIndex={-1} role='dialog'>
                <div className='modal' ref={refOutside}>
                    <div className='modal-content mid'>{content}</div>
                    <div className='modal__button-group token'>
                        <Button
                            type='button'
                            atr='short'
                            wrapperClassname='full-width'
                            data-dismiss='modal'
                            text='Accept'
                            handleClick={handleDispatch}
                        />
                        <Button
                            type='button'
                            atr='short'
                            wrapperClassname='full-width'
                            data-dismiss='modal'
                            text='Cancel'
                            handleClick={HandleCancel}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>,
        document.body
    );
};
export default ApproveModal;
