/** @format */

import React from 'react';
import GoldCards from './GoldCards';
import WoodCards from './WoodCards';
import FoodCards from './FoodCards';
import { useSelector } from 'react-redux';
export default function SatelliteCards() {
    const selectedMap = useSelector((state) => state.game.selectedMap);
    const usingItems = useSelector((state) => state.tools.usingItems);
    const woodCards = usingItems.filter((item) => item.type === 'Wood');
    const foodCards = usingItems.filter((item) => item.type === 'Food');
    const goldCards = usingItems.filter((item) => item.type === 'Gold');

    return (
        selectedMap === 0 && (
            <section className='satellite_cards-container'>
                <div className='satellite_cards-content'>
                    <FoodCards cards={foodCards} />
                    <GoldCards cards={goldCards} />
                    <WoodCards cards={woodCards} />
                </div>
            </section>
        )
    );
}
