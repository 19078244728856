/** @format */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import FarmersWorld from '../../api/farmersworld';
import { raceAll } from '../../utils/index';
import jwt_decode from 'jwt-decode';
import { isRepeatable } from '../../utils';

const SERVERS_URL = [
    'https://wax.pink.gg',
    'https://api.wax.alohaeos.com',
    'https://api.waxsweden.org',
    'https://wax.greymass.com'
];
// const SERVERS_URL = ['https://testnet.wax.pink.gg'];
// "https://testnet.wax.pink.gg",
const game = new FarmersWorld(
    'https://wax.pink.gg',
    'farmersworld',
    'farmerstoken',
    //     {
    //         username: 'farmerstest2',
    //         privateKey: '5KeJtUKPGx726yrHcJZ77WnXVRvnD4Dq8hc368ENq82fowv4QoR'
    //     }
    // );
    // 	{
    // 		username: 'farmerswwwww',
    // 		privateKey: '5JEcUVvxYoZKC5rDDhQsCFSgKXk6AvWn4wwvZr49Rvx5uUKNtkZ',
    // 	}
    // );
    // 	{
    // 		username: 'farmerssssss',
    // 		privateKey: '5HwGNMqHHSWx3XvjjyfjbkSkixxv48N2K3VsbGYhvzPurkfviqH',
    // 	}
    // );
    // 	{
    // 		username: 'farmersmem11',
    // 		privateKey: '5JPyfxaZxmweevP9aRN43K5FwS696c4cEk1Pd3rJNrhjLLmUayQ',
    // 	}
    // );
    //     {
    //         username: 'farmerstests',
    //         privateKey: '5JjAtJob3R1hc6hN9wSHwp185Y9nrwjtDYtcopVMka9V21gyz8J'
    //     }
    // );
    // 	{
    // 		username: 'bestfarmers1',
    // 		privateKey: '5KHuj7NvVpkhFykkpYFMYaup78p8bKg9S9NjRxE7JheCPhkgJuj',
    // 	}
    // );

    //     {
    //         username: '.uqqy.wam',
    //         privateKey: '5J1VDb6TQzv2HFsyiGPrx2jBJeHCWsPKLDsuMag5aXrSmm5QpRM'
    //     }
    // ); //test
    null
);

export const login2FA = createAsyncThunk('auth/login2FA', async () => {
    const nonce = await game.getNonce();
    const { serializedTransaction, signatures, transaction } =
        await game.getProof(nonce);
    const proof = {
        serializedTransaction: serializedTransaction,
        signatures: signatures,
        transaction: transaction
    };
    for (let count = 0; count < 3; count++) {
        try {
            const response = await game.login2FA(proof, nonce);
            return response;
        } catch (error) {
            if (isRepeatable(error.message) && count < 2) continue;
            throw new Error('Failed to Login: ' + error.message);
        }
    }
});

export const verifyOtp = createAsyncThunk(
    'auth/verifyOtp',
    async (otpCode, { getState }) => {
        const response = await game.verifyOtp(otpCode);
        if (response.flag === 2) {
            await game.verifyOtp();
        }
        return response;
        // const
    }
);
export const enable2FA = createAsyncThunk('auth/enable2FA', async () => {
    const response = await game.enable2FA();
    return response;
});

export const disable2FA = createAsyncThunk('auth/disable2FA', async () => {
    const response = await game.disable2FA();
    return response;
});

export const setAuthSettings = createAsyncThunk(
    'auth/setAuthSettings',
    async (data, { getState, dispatch }) => {
        const { auth } = getState();
        let flag = !!auth.authSettings.features;
        for (let index = 0; index < 3; index++) {
            try {
                const response = await game.authRequiredTransaction(
                    game.verify2fa(flag),
                    game.setAuthSettings(data)
                );
                if (
                    !auth.authSettings.locked &&
                    (!!response.transaction_id ||
                        !!response.processed ||
                        !!response.transaction) &&
                    !flag
                ) {
                    await game.lock2FA();
                }
                await game.update2FADuration(data.unlock_duration);

                response.unlockDuration = data.unlock_duration;

                await dispatch(getAuthSettings()).unwrap();
                return response;
            } catch (error) {
                let temp = JSON.stringify(error);
                if (temp.includes('Error expected key')) {
                    await game.lock2FA();
                }
                if (isRepeatable(error.message) && index < 2) continue;
                throw error;
            }
        }
    }
);

export const removeAuthSettings = createAsyncThunk(
    'auth/removeAuthSettings',
    async (_, { dispatch }) => {
        for (let index = 0; index < 3; index++) {
            try {
                const response = await game.authRequiredTransaction(
                    game.verify2fa(true),
                    game.removeAuthSettings()
                );
                if (
                    !!response.transaction_id ||
                    !!response.processed ||
                    !!response.transaction
                )
                    await dispatch(disable2FA()).unwrap();
                return response;
            } catch (error) {
                if (isRepeatable(error) && index < 2) continue;
                throw error;
            }
        }
    }
);

export const getAuthStatus = createAsyncThunk(
    'auth/getAuthStatus',
    async (_, { getState }) => {
        const { user } = getState();
        for (let count = 0; count < 3; count++) {
            try {
                const response = await game.getAuthStatus();
                response.name = user.info.account;
                return response;
            } catch (error) {
                if (isRepeatable(error.message) && count < 2) continue;
                throw error;
            }
        }
    }
);

export const disableOffchain2FA = createAsyncThunk(
    'auth/disableOffchain2FA',
    async (_, { dispatch }) => {
        const beResponse = await game.chainDisable2FA();
        if (!beResponse.ok) throw new Error(beResponse.message);
        dispatch(getRemove2FARequest());
        return beResponse;
    }
);

export const claimRemove2FARequest = createAsyncThunk(
    'auth/claimRemove2FARequest',
    async (_, { dispatch, getState }) => {
        const { user } = getState();
        const account = user.info.account;
        const jwtToken = localStorage.getItem(`s.id ${account}`);
        if (!jwtToken) {
            await dispatch(login2FA()).unwrap();
        }
        const response = await game.claimRemove2FARequest();
        if (!!response?.transaction_id || !!response?.processed) {
            const beResponse = await game.chainDisable2FA();
            if (!beResponse.ok) throw new Error(beResponse.message);
            dispatch(getRemove2FARequest());
            return beResponse;
        }
    },
    {
        condition: (data, { getState, extra }) => {
            const { auth } = getState();
            const fetchStatus = auth.requests.filter(
                (__item_id) => __item_id === 1
            );
            if (fetchStatus.length !== 0) {
                return false;
            }
        }
    }
);
export const cancelRemove2FARequest = createAsyncThunk(
    'auth/cancelRemove2FARequest',
    async (_, { dispatch }) => {
        for (let count = 0; count < 3; count++) {
            try {
                const response = await game.cancelRemove2FARequest();
                dispatch(getRemove2FARequest());
                return response;
            } catch (error) {
                if (isRepeatable(error.message) && count < 2) continue;
                throw error;
            }
        }
    }
);
export const getRemove2FARequest = createAsyncThunk(
    'auth/getRemove2FARequest',
    async () => {
        for (let index = 0; index < 3; index++) {
            try {
                const response = await game.getRemove2FARequest();
                return response;
            } catch (error) {
                if (isRepeatable(error.message) && index < 2) continue;
                throw error;
            }
        }
    }
);
export const checkServersHealth = createAsyncThunk(
    'auth/checkServersHealth',
    async (_, { rejectWithValue }) => {
        let res = [];
        for (let index = 0; index < 3; index++) {
            try {
                res = await raceAll(
                    SERVERS_URL.map(async (link) => {
                        try {
                            const result = await axios.post(
                                link + '/v1/chain/get_table_rows',
                                {
                                    json: true,
                                    code: 'farmersworld',
                                    scope: 'farmersworld',
                                    table: 'config',
                                    limit: '1'
                                }
                            );
                            return { response: result, server: link };
                        } catch (error) {
                            return { response: false, server: link };
                        }
                    }),
                    3000,
                    { reponse: false }
                ).then((results) => {
                    let final = results.filter((item) => !!item.response);
                    if (final.length) return final;
                    if (index === 2 && final.length < 1)
                        rejectWithValue('Failed to connect to server');
                });
                if (res.length) return res;
            } catch (err_1) {
                console.error('checkServersHealth', err_1);
            }
        }
        rejectWithValue('Failed to connect to server');
    }
);

export const getAuthSettings = createAsyncThunk(
    'auth/getAuthSettings',
    async () => {
        for (let index = 0; index < 3; index++) {
            try {
                const response = await game.getAuthSettings();
                return response;
            } catch (error) {
                if (isRepeatable(error.message) && index < 2) continue;
                throw error;
            }
        }
    }
);

export const login = createAsyncThunk('auth/login', async () => {
    const name = await game.login();
    // console.log("login", name)
    return name;
});

export const register = createAsyncThunk('auth/register', async () => {
    const referral = localStorage.getItem('referral');
    const response = await game.register(referral);
    return response;
});

export const waxLogin = createAsyncThunk('auth/waxLogin', async () => {
    const response = await game.login();
    console.log('login response', response);
    return response;
});

export const anchorLogin = createAsyncThunk('auth/anchorLogin', async () => {
    const response = await game.anchorLogin();
    console.log('anchor login response', response);
    return response;
});

export { game };
// https://testnet.waxsweden.org
// https://wax.greymass.com
export const AuthSlicer = createSlice({
    name: 'auth',
    initialState: {
        user: {
            server: 'https://wax.greymass.com',
            //https://testnet.waxsweden.org
            // server: 'https://wax.greymass.com',
            accountCollection: 'farmersworld',
            mintCollection: 'farmerstoken',
            account: {
                name: '',
                key: ''
            }
        },
        isLoggedIn: false,
        isRegisteredStatus: false,
        splash: false,
        authSettings: {
            account: '',
            features: 0,
            pubkey: '',
            unlockDuration: 0
        },
        authFlag: null,
        timestamp: null,
        isLogin2FA: null,
        isSCReistered2FA: null,
        isUnlocked2FA: null,
        requestInfo: {},
        error: null,
        servers: [],
        requests: [],
        selectedServer: '',
        status: ''
    },
    reducers: {
        submitUser: (state, action) => {
            state.user.account.name = action.payload.account;
            state.user.account.key = action.payload.key;
            const username = state.user.account.name;
            const privateKey = state.user.account.key;
            game.setPrivateAccount({ username, privateKey });
            state.isLoggedIn = true;
        },
        setServer: (state, action) => {
            state.selectedServer = state.servers[action.payload];
            game.setServer(state.selectedServer);
        },

        setRegisterStatus: (state, action) => {
            state.isRegisteredStatus = action.payload;
        },
        setSplahScreen: (state, action) => {
            state.splash = action.payload;
        },
        setLoginStatus: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setUnlock2FA: (state, action) => {
            state.isUnlocked2FA = action.payload;
        },
        setLogin2FA: (state, action) => {
            state.isLogin2FA = action.payload;
        },
        resetSignature: (state, action) => {
            game.clearSig();
            state.timestamp = null;
            state.isUnlocked2FA = null;
        },
        isValidbackupKey: (state, action) => {
            try {
                let flag = game.isValidbackupKey(action.payload);
                if (flag) {
                    state.isUnlocked2FA = true;
                }
            } catch (error) {
                throw error;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(login.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoggedIn = true;
                state.user.name = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(anchorLogin.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(anchorLogin.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoggedIn = true;
                state.user.name = action.payload.actor;
            })
            .addCase(anchorLogin.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(register.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(register.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isRegisteredStatus = true;
            })
            .addCase(register.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(waxLogin.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(waxLogin.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoggedIn = true;
                state.user.name = action.payload;
            })
            .addCase(waxLogin.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(checkServersHealth.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(checkServersHealth.fulfilled, (state, action) => {
                state.status = 'succeeded';
                action.payload.forEach((data) => {
                    // const isOkay = data.response.data?.health[1].status === "OK"
                    // if (isOkay) {
                    state.servers.push(data.server);
                    // }
                });
                state.selectedServer = state.servers[0];
                game.setServer(state.servers[0]);
            })
            .addCase(checkServersHealth.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getAuthSettings.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getAuthSettings.fulfilled, (state, action) => {
                state.status = 'succeeded';

                if (action.payload.length > 0) {
                    state.authSettings.account = action.payload[0].account;
                    state.authSettings.pubkey = action.payload[0].pubkey;
                    state.authSettings.features = action.payload[0].features;
                    state.isSCReistered2FA = true;
                    state.authFlag = false;
                } else {
                    state.isSCReistered2FA = false;
                    state.authFlag = true;
                }
            })
            .addCase(getAuthSettings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(login2FA.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(login2FA.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if (action.payload.token) {
                    const decoded = jwt_decode(action.payload.token);
                    localStorage.setItem(
                        `s.id ${decoded.waxAddress}`,
                        action.payload.token
                    );
                    state.isLogin2FA = true;
                } else {
                    state.isLogin2FA = false;
                }
            })
            .addCase(login2FA.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(verifyOtp.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(verifyOtp.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if (action.payload.ok) {
                    if (action.payload.flag === 2)
                        state.timestamp = action.payload.timestamp;
                    state.isUnlocked2FA = true;
                } else {
                    state.isUnlocked2FA = false;
                }
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(enable2FA.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(enable2FA.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.authSettings.otpSecret = action.payload.otpSecret;
                state.authSettings.publicKey = action.payload.publicKey;
                state.authSettings.privateKey = action.payload.privateKey;
                state.authSettings.name = action.payload.name;
            })
            .addCase(enable2FA.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(disable2FA.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(disable2FA.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isUnlocked2FA = false;
                state.isSCReistered2FA = false;
                state.authSettings = {
                    account: '',
                    features: 0,
                    pubkey: '',
                    unlockDuration: 0
                };
            })
            .addCase(disable2FA.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(setAuthSettings.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(setAuthSettings.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.authSettings.unlockDuration =
                    action.payload.unlockDuration;
            })
            .addCase(setAuthSettings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(removeAuthSettings.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(removeAuthSettings.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.authSettings = {
                    account: '',
                    features: 0,
                    pubkey: '',
                    unlockDuration: 0
                };
                state.isUnlocked2FA = false;
                state.isSCReistered2FA = false;
            })
            .addCase(removeAuthSettings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getAuthStatus.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getAuthStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if (action.payload.enabled) {
                    if (!action.payload.locked) {
                        state.authSettings.otpSecret = action.payload.otpSecret;
                        state.authSettings.pubkey = action.payload.publicKey;
                        state.authSettings.privateKey =
                            action.payload.privateKey;
                    } else {
                        state.authSettings.pubkey = action.payload.publicKey;
                        state.authSettings.unlockDuration =
                            action.payload.unlockDuration;
                        state.authSettings.locked = action.payload.locked;
                        state.authSettings.enabled = action.payload.enabled;
                    }
                }
            })
            .addCase(getAuthStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getRemove2FARequest.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getRemove2FARequest.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.requestInfo = [];
                if (action.payload?.length) {
                    state.requestInfo = action.payload[0];
                }
            })
            .addCase(getRemove2FARequest.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(claimRemove2FARequest.pending, (state, action) => {
                state.status = 'loading';
                state.requests.push(1);
            })
            .addCase(claimRemove2FARequest.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.requests = [];
            })
            .addCase(claimRemove2FARequest.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.requests = [];
            })

            .addCase(disableOffchain2FA.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(disableOffchain2FA.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(disableOffchain2FA.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(cancelRemove2FARequest.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(cancelRemove2FARequest.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(cancelRemove2FARequest.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const {
    submitUser,
    setRegisterStatus,
    setSplahScreen,
    setLoginStatus,
    setServer,
    setUnlock2FA,
    setLogin2FA,
    resetSignature,
    isValidbackupKey
} = AuthSlicer.actions;

export default AuthSlicer.reducer;
