import React, { useState } from 'react';
import styles from './request.module.scss';
import { ReactComponent as Close } from './close.svg';
import Button from '../Account2fa/Button';
import ApproveModal from '../Modal/ApproveModal';
import {
    cancelRemove2FARequest,
    claimRemove2FARequest
} from '../../redux/slice/authSlicer';

const Remove2FARequest = ({ requestInfo }) => {
    const [isClosed, setClosed] = useState(false);
    const [content, setContent] = useState('');
    const [isModal, setModal] = useState(false);
    const [HandleFn, setHandleFn] = useState(Function);
    const cancel2FA = () => {
        setContent('Are you sure you want to remove 2FA Request?');
        setHandleFn(cancelRemove2FARequest);
        setModal(true);
    };
    const disable2FA = () => {
        setContent('Are you sure you want to remove 2FA?');
        setHandleFn(claimRemove2FARequest);
        setModal(true);
    };
    const isDisable = requestInfo.unlock_at <= Date.now() / 1000;
    let time = requestInfo.unlock_at - Date.now() / 1000;
    let timeStr =
        time > 86400
            ? parseFloat(time / 86400).toFixed(0) + ' days'
            : time > 3600
            ? parseFloat(time / 3600).toFixed(0) + ' hours'
            : time > 0
            ? parseFloat(time).toFixed(0) + ' seconds'
            : 0 + ' seconds';
    return (
        !isClosed && (
            <>
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <Close
                            width='1.6rem'
                            height='1.6rem'
                            className={styles.close}
                            onClick={() => {
                                setClosed(true);
                            }}
                        />
                        <div className={styles.big}>
                            Your Two-factor Authentication will be disabled
                            after
                        </div>
                        <div className={styles.red}>{timeStr}.</div>
                        <small>
                            {isDisable
                                ? 'You can now click Remove to remove your 2FA'
                                : 'If this is not your request, click Cancel and we will help you secure your account.'}
                        </small>
                        <div className={styles.button_wrapper}>
                            {isDisable ? (
                                <Button
                                    title='Remove'
                                    handleClick={() => disable2FA()}
                                />
                            ) : (
                                <Button
                                    title='Cancel'
                                    handleClick={() => cancel2FA()}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {isModal && (
                    <ApproveModal
                        content={content}
                        HandleCancel={() => setModal(false)}
                        handleClick={HandleFn}
                    />
                )}
            </>
        )
    );
};

export default Remove2FARequest;
