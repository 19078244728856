import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import styles from './ExchangeModal.module.scss';
import Button from '../Button';
import { useDispatch } from 'react-redux';
import Close from '../../assets/img/close-button.png';
import { setErrorMessage, toggleModal } from '../../redux/slice/modalSlice';
import getErrorMessages from '../../utils/getErrorMessages';
import { exchangeRewards, getTransaction } from '../../redux/slice/FoodsSlicer';
import { deleteFlash, setFlash } from '../../redux/slice/FlashSlicer';
import minusicon from '../../assets/img/minus.png';
import plusicon from '../../assets/img/plus.png';
import { getPlayerInfo } from '../../redux/slice/userSlicer';
import { popAssets } from '../../redux/slice/AtomicSlicer';
const ExchangeModal = ({ card, handleCancel, max }) => {
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        event.preventDefault();
        let tempValue = event.target.value;
        if (tempValue <= max && tempValue >= 0) setTotal(event.target.value);
    };

    const handleExchange = async () => {
        const flashId = Date.now();
        try {
            if (total > 0) {
                const response = await dispatch(
                    exchangeRewards({ card: card, total: total })
                ).unwrap();
                const flashMessage = {
                    id: flashId,
                    content: `Exchanging your ${total} ${card.name} to Gold`,
                    timeout: 30000
                };
                dispatch(setFlash(flashMessage));
                if (!!response.transaction_id || !!response.processed) {
                    dispatch(popAssets({ card: card, total: total }));
                    const result = await dispatch(
                        getTransaction({
                            transactionId: response.transaction_id,
                            total: total
                        })
                    ).unwrap();
                    if (result.rewards) {
                        dispatch(setErrorMessage(`Got ${result.rewards}`));
                        dispatch(toggleModal(true));
                    }
                    await dispatch(getPlayerInfo()).unwrap();
                }
            }
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
        }
        setTotal(0);
        dispatch(deleteFlash(flashId));
    };
    return ReactDOM.createPortal(
        <React.Fragment>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <img
                            src={Close}
                            alt='close'
                            onClick={handleCancel}
                            className={styles.img}
                        />
                    </div>
                    <div className={styles.content}>
                        <p className={styles.text}>
                            You are having {max} {card.name}
                        </p>
                        <div className={styles.input__wrapper}>
                            <img
                                src={minusicon}
                                alt='minus'
                                className={styles.minus}
                                onClick={() =>
                                    setTotal(total - 1 >= 0 ? total - 1 : 0)
                                }
                            />
                            <input
                                type='number'
                                min={0}
                                max={max}
                                value={total}
                                onChange={(e) => handleChange(e)}
                                className={styles.input}
                            />
                            <img
                                src={plusicon}
                                alt='plus'
                                className={styles.plus}
                                onClick={() =>
                                    setTotal(
                                        total + 1 > max ? total : total + 1
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <Button
                            handleClick={handleExchange}
                            text='Exchange'
                            atr='short'
                            wrapperClassname='full-width'
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>,
        document.body
    );
};

export default ExchangeModal;
