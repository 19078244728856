import React from 'react';
import { ChooseTab } from '../../redux/slice/navBarSlice';

import Button from '../Button';
import { useDispatch } from 'react-redux';
export default function EmptyTab() {
    const dispatch = useDispatch();
    return (
        <section className='empty-container'>
            <div className='empty-content'>
                <div className='empty-content-wrapper'>
                    <img src='./img/noitems.png' alt='Go For Cardssssssss' />
                    <div className='empty-text'>
                        You need NFT game cards to start minning !
                    </div>
                    <div className='empty-group__button'>
                        <a
                            className='link'
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://wax.atomichub.io/market?collection_name=farmersworld&order=desc&sort=created&symbol=WAX'>
                            <Button
                                text='Go to Atomic'
                                atr='semi-long '
                                wrapperClassname='full-width'
                                handleClick={() => dispatch(ChooseTab(1))}
                            />
                        </a>
                        <Button
                            text='Go to Smithy'
                            atr='semi-long '
                            wrapperClassname='full-width'
                            handleClick={() => dispatch(ChooseTab(2))}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
