import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deleteFlash } from '../../redux/slice/FlashSlicer';

export default function FlashMessage({ id, timeout, content }) {
    const dispatch = useDispatch();
    useEffect(() => {
        const countdown = setTimeout(() => {
            dispatch(deleteFlash(id));
        }, timeout);
        return () => {
            clearTimeout(countdown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeout, id]);
    return (
        <div className='flash-message-wrapper'>
            <div className='flash-message-content'>{content}</div>
        </div>
    );
}
