import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import store from './redux/store/index.jsx'
import { Provider } from 'react-redux'
// import ReactGA from 'react-ga';
// ReactGA.initialize('G-V1QGPTPEF3');
// ReactGA.pageview(window.location.pathname + window.location.search);
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
Sentry.init({
  dsn: "https://1dc75f01ef284d52a2569bc210f75dcd@o946368.ingest.sentry.io/5895298",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />

    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
