import { createSlice } from '@reduxjs/toolkit';

export const modalSlicer = createSlice({
    name: 'modal',
    initialState: {
        isShowing: false,
        error: ''
    },
    reducers: {
        toggleModal: (state, action) => {
            state.isShowing = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.error = action.payload;
        }
    }
});

export const { toggleModal, setErrorMessage } = modalSlicer.actions;

export default modalSlicer.reducer;
