/** @format */

import React from 'react';
import { getPicture } from '../../utils';

export default function CardList({ data, selected, HandleChoose }) {
    return (
        <section className='vertical-carousel-container'>
            {data?.map(
                (item, i) =>
                    item?.img && (
                        <img
                            src={getPicture(item.img)}
                            onError={(e) => {
                                e.target.onerror = null; // prevents looping
                                e.currentTarget.src = getPicture(item.img);
                            }}
                            alt={i}
                            className={
                                i === selected
                                    ? 'carousel__img--item active'
                                    : 'carousel__img--item'
                            }
                            key={i}
                            onClick={() => HandleChoose(i)}
                        />
                    )
            )}
        </section>
    );
}
