/** @format */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeData } from './exchangeSlice';
const mapResourceTypes = {
    FWG: 'gold',
    FWW: 'wood',
    FWF: 'food'
};

/**
 *
 * @type {type} gold / wood / food / coin
 * @exchangeValue {exchangeValue}
 * @initialResource {initialResource}
 * @image {image}
 * @resource {resource}
 * @setCoin {function} this is for Farmerscoin only
 * @returns
 */
export default function ExchangeInput({
    type,
    exchangeValue,
    initialResource,
    image,
    resource,
    setCoin
}) {
    const dispatch = useDispatch();
    const [__exchangeValue, setExchangeValue] = useState(0);
    const [max, setMax] = useState(0);
    const tax = useSelector((state) => state.exchange.tax);

    useEffect(() => {
        if (type === 'straight') {
            setExchangeValue(exchangeValue);
            setMax(parseFloat(initialResource || 0));
        } else {
            setExchangeValue((exchangeValue * (100 - tax)) / 100);
            setMax(parseFloat(initialResource || 0));
        }
    }, [exchangeValue, type, tax, initialResource]);

    const handleChange = (e) => {
        const changedValue = e.target.value;
        if (type === 'straight') {
            if (resource === 'Coins') {
                if (changedValue >= max) {
                    setCoin(max);
                } else if (changedValue <= 0) {
                    setCoin(0);
                } else setCoin(changedValue);
            } else {
                if (changedValue > parseFloat(initialResource)) {
                    dispatch(
                        changeData({
                            [mapResourceTypes[resource] || resource]:
                                parseFloat(initialResource)
                        })
                    );
                } else if (changedValue < 0) {
                    dispatch(
                        changeData({
                            [mapResourceTypes[resource] || resource]: 0
                        })
                    );
                } else {
                    dispatch(
                        changeData({
                            [mapResourceTypes[resource] || resource]:
                                changedValue
                        })
                    );
                }
            }
        } else {
            const change = (changedValue * 100) / (100 - tax);

            if (change > parseFloat(initialResource)) {
                dispatch(
                    changeData({
                        [mapResourceTypes[resource] || resource]: max
                    })
                );
            } else if (change < 0) {
                dispatch(changeData({ [mapResourceTypes[resource]]: 0 }));
            } else {
                dispatch(changeData({ [mapResourceTypes[resource]]: change }));
            }
        }

        e.preventDefault();
    };
    return (
        <div className='exchange-input-container'>
            <input
                type='number'
                min={0}
                max={max}
                value={__exchangeValue}
                lang='en'
                inputMode={resource === 'Coins' ? 'number' : 'decimal'}
                className='exchange-input'
                onChange={(e) => handleChange(e)}
            />

            <div className='input-append'>
                <img
                    src={image}
                    alt={resource}
                    className='input-resource--image'
                />
                <div className='input-resource'>{resource}</div>
            </div>
        </div>
    );
}
