import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../components/Button';

import farmersCoin from '../../assets/img/Farmer-coin.png';
import Coinbalance from './CoinBalance';

import styles from './WithdrawCoin.module.scss';
import ExchangeInput from './ExchangeInput';

import getErrorMessages from '../../utils/getErrorMessages';
import { getWaxAccount, setUpdate } from '../../redux/slice/GameSlicer';
import { setErrorMessage, toggleModal } from '../../redux/slice/modalSlice';
import { getStakedCoin, sendCoins } from '../../redux/slice/CoinSlicer';

export default function TransferTab() {
    const stakedCoin = useSelector((state) => state.coin.stakedCoin);

    const [coin, setCoin] = useState(0);
    const [receiver, setReceiver] = useState('');
    const dispatch = useDispatch();
    const handleTransfer = async () => {
        if (!receiver.length) {
            dispatch(setErrorMessage('Unrecognized Receiver'));
            dispatch(toggleModal(true));

            return;
        }
        try {
            const firstRes = await dispatch(getWaxAccount(receiver)).unwrap();
            if (!!firstRes?.account_name) {
                const response = await dispatch(
                    sendCoins({ to: receiver, amount: coin })
                ).unwrap();
                if (!!response.transaction_id) {
                    dispatch(setUpdate(true));
                    dispatch(
                        setErrorMessage('Transaction completed successfully')
                    );
                }
            } else {
                dispatch(setErrorMessage('Unrecognized Receiver'));
                dispatch(toggleModal(true));
            }
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
            dispatch(setUpdate(true));
        }
        await dispatch(getStakedCoin()).unwrap();
    };

    return (
        <div className='withdrawTab-container'>
            <div className={styles.container}>
                <div className={styles.header}>
                    You have
                    <Coinbalance amount={stakedCoin} img={farmersCoin} />
                    in your Stack
                </div>
                <div className={styles.group}>
                    <div className={styles.title}>Receiver</div>

                    <div className='exchange-input-container'>
                        <input
                            placeholder='Enter your Receiver'
                            type='text'
                            value={receiver}
                            lang='en'
                            className='exchange-input'
                            onChange={(e) => setReceiver(e.target.value)}
                        />
                    </div>
                </div>
                <div className={styles.group}>
                    <div className={styles.title}>Amount</div>
                    <div className={styles.content}>
                        <ExchangeInput
                            type='straight'
                            exchangeValue={coin}
                            initialResource={stakedCoin}
                            image={farmersCoin}
                            resource={'Coins'}
                            setCoin={setCoin}
                        />
                    </div>
                </div>
                <div className='withdraw-button tooltip'>
                    <Button
                        text={'Transfer'}
                        handleClick={handleTransfer}
                        atr='semi-short '
                        isDisabled={receiver !== '' ? false : 'disabled'}
                        wrapperClassname='half-width'
                        setCoin={setCoin}
                    />
                </div>
            </div>
        </div>
    );
}
