/** @format */

import React, { useState } from 'react';
import './index.scss';
import Card from './card';
import CardList from '../../components/CardList/CardList';
import Button from '../../components/Button';

import { useDispatch, useSelector } from 'react-redux';
import { craftTool } from '../../redux/slice/ToolsSlicer';
import {
    atomicUpdate,
    cancelLoading,
    setBackgroundUpdate
} from '../../redux/slice/GameSlicer';
import { toggleModal, setErrorMessage } from '../../redux/slice/modalSlice';
import { deleteFlash, setFlash } from '../../redux/slice/FlashSlicer';
import {
    mbsCraft,
    getMbsCraftTransaction,
    mbsClaimAsset
} from '../../redux/slice/BadgeSlicer';
import { craftBuilding } from '../../redux/slice/BuildingSlicer';
import woodtypeicon from '../../assets/img/WOOD-type-icon.svg';
import goldtypeicon from '../../assets/img/GOLD-type-icon.svg';
import foodtypeicon from '../../assets/img/FOOD-type-icon.svg';
import getErrorMessages from '../../utils/getErrorMessages';
import TicketInput from './TicketInput';
import { getStakedCoin } from '../../redux/slice/CoinSlicer';

const resourceIconMapping = {
    Wood: woodtypeicon,
    Gold: goldtypeicon,
    Food: foodtypeicon
};

export default function Smithy() {
    const [selectedCardIndex, setSetlectedCardIndex] = useState(0);
    const [ticket, setTicket] = useState(0);

    const EquipConfigs = useSelector((state) => state.tools.EquipConfigs);
    const buildConfig = useSelector((state) => state.builds.buildConfig);
    const badgeCraft = useSelector((state) => state.badge.badgeCraft);
    const stakedCoin = useSelector((state) => state.coin.stakedCoin);
    const crafts = EquipConfigs?.concat(buildConfig)?.concat(badgeCraft);

    const balances = useSelector((state) => state.user.balances);
    const totalCoin = useSelector((state) => state.coin.totalCoin);
    const coinsId = useSelector((state) => state.coin.coinsId);
    const ticketsChest = useSelector((state) => state.atomic.tickets);
    const craft = crafts[selectedCardIndex];
    const dispatch = useDispatch();

    const isCraftable =
        (balances?.wood >= craft?.wood_mint ||
            totalCoin + stakedCoin >= craft?.coins_mint) &&
        balances?.gold >= (craft?.gold_mint || craft?.golds_mint)
            ? null
            : 'disabled';

    const HandleCraft = () => {
        if (craft.num_slots) {
            HandleBuildingCraft();
        } else if (craft.saved_claims) {
            HandleMbsCraft();
        } else {
            HandleToolCraft();
        }
    };

    const HandleToolCraft = async () => {
        try {
            dispatch(cancelLoading(false));
            if (isCraftable === null) {
                const resultAction = await dispatch(
                    craftTool({ craft: craft, ticket: ticket })
                ).unwrap();
                if (resultAction?.transaction_id !== null) {
                    const flash_id = Date.now();
                    const flashMessage = {
                        id: flash_id,
                        content: `${craft.template_name} is on the way sir!`,
                        timeout: 5000
                    };
                    dispatch(setFlash(flashMessage));
                    // const { claim } = await dispatch(
                    // 	getCraftTransaction(resultAction?.transaction_id)
                    // ).unwrap();
                    // if (claim) {
                    // with discount: ${claim.discount}%
                    const inform = `You have craft ${craft.template_name} successfully `;
                    dispatch(setErrorMessage(inform));
                    dispatch(toggleModal(true));
                    dispatch(deleteFlash(flash_id));
                    // }
                }
            } else {
                dispatch(
                    setErrorMessage(
                        `You dont' have enough resources to craft ${craft.template_name}!`
                    )
                );
                dispatch(toggleModal(true));
            }
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
        } finally {
            dispatch(setBackgroundUpdate(true));
        }
    };

    const HandleMbsCraft = async () => {
        try {
            dispatch(cancelLoading(false));
            if (isCraftable === null) {
                const requiredCoin =
                    craft.coins_mint - stakedCoin <= 0
                        ? 0
                        : craft.coins_mint - stakedCoin;
                console.log(requiredCoin);
                let craftCoin = coinsId.slice(0, requiredCoin);
                const resultAction = await dispatch(
                    mbsCraft({ name: craft.name, coins_id: craftCoin })
                ).unwrap();
                if (resultAction?.transaction_id !== null) {
                    const flash_id = Date.now();
                    const flashMessage = {
                        id: flash_id,
                        content: `${craft.name} is on the way sir!`,
                        timeout: 5000
                    };
                    dispatch(setFlash(flashMessage));
                    const response = await dispatch(
                        getMbsCraftTransaction(resultAction?.transaction_id)
                    ).unwrap();
                    if (response) {
                        let temp = [];
                        temp.push(response.asset_id);
                        const res2 = await dispatch(
                            mbsClaimAsset(temp)
                        ).unwrap();
                        if (res2) {
                            const inform = 'You have craft successfully.';
                            dispatch(setErrorMessage(inform));
                            dispatch(toggleModal(true));
                            dispatch(setBackgroundUpdate(true));
                            dispatch(deleteFlash(flash_id));
                        }
                    }
                    await dispatch(atomicUpdate()).unwrap();
                }
            } else {
                dispatch(
                    setErrorMessage(
                        "You dont' have enough tokens to craft this tool!"
                    )
                );
                dispatch(toggleModal(true));
            }
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
            dispatch(setBackgroundUpdate(true));
            await dispatch(atomicUpdate()).unwrap();
        }
        await dispatch(getStakedCoin()).unwrap();
    };

    const HandleBuildingCraft = async () => {
        try {
            dispatch(cancelLoading(false));
            if (isCraftable === null) {
                const resultAction = await dispatch(
                    craftBuilding({
                        template_id: craft.template_id,
                        ticket: ticket
                    })
                ).unwrap();
                if (resultAction?.transaction_id !== null) {
                    const flash_id = Date.now();
                    const flashMessage = {
                        id: flash_id,
                        content: `${craft.name} is on the way sir!`,
                        timeout: 5000
                    };
                    dispatch(setFlash(flashMessage));
                    // const { claim } = await dispatch(
                    // 	getBuildingTransaction(resultAction?.transaction_id)
                    // ).unwrap();
                    // if (claim) {
                    // with discount ${claim.discount}%
                    const inform = `You have craft ${craft.name} successfully `;
                    dispatch(setErrorMessage(inform));
                    dispatch(toggleModal(true));
                    // }
                    dispatch(deleteFlash(flash_id));
                    await dispatch(atomicUpdate()).unwrap();
                }
            } else {
                dispatch(
                    setErrorMessage(
                        `You dont' have enough resources to craft ${craft.name}!`
                    )
                );
                dispatch(toggleModal(true));
            }
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
            await dispatch(atomicUpdate()).unwrap();
        } finally {
            dispatch(setBackgroundUpdate(true));
        }
    };
    const HandleChoose = (index) => {
        setSetlectedCardIndex(index);
    };

    return (
        <section className='home-container'>
            <div className='home-content'>
                <CardList
                    data={crafts}
                    selected={selectedCardIndex}
                    HandleChoose={HandleChoose}
                />
                <div className='home__card-container'>
                    <Card {...craft} />
                    <div className='smithy-info-container'>
                        <div className='info-section'>
                            <div className='info-text__section'>
                                <div className='info-title'>
                                    {craft?.type && (
                                        <div
                                            className='info-type-icon'
                                            style={{
                                                backgroundImage: `url(${
                                                    resourceIconMapping[
                                                        craft?.type
                                                    ]
                                                })`
                                            }}></div>
                                    )}
                                    {craft?.num_slots && (
                                        <div
                                            className='info-type-icon'
                                            style={{
                                                backgroundImage: `url("/img/buidling-icon.svg")`
                                            }}></div>
                                    )}
                                    <div className='info-title-name'>
                                        {craft?.template_name || craft?.name}
                                    </div>
                                    {craft?.level && (
                                        <div className='info-title-level'>
                                            LV: {craft?.level || null}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='info-content'>
                                {craft?.rarity && (
                                    <div className='info-label'>
                                        Rarity:
                                        <div className='info-description'>
                                            {craft?.rarity || null}
                                        </div>
                                    </div>
                                )}
                                {craft?.additional_slots >= 0 && (
                                    <div className='info-label'>
                                        Extra Slots:
                                        <div className='info-description'>
                                            {craft?.additional_slots || 0}
                                        </div>
                                    </div>
                                )}
                                {craft?.additional_energy >= 0 && (
                                    <div className='info-label'>
                                        Extra Energy:
                                        <div className='info-description'>
                                            {craft?.additional_energy || 0}
                                        </div>
                                    </div>
                                )}
                                {craft?.saved_claims >= 0 && (
                                    <div className='info-label'>
                                        Stored Mining:
                                        <div className='info-description'>
                                            {craft?.saved_claims || 0}
                                        </div>
                                    </div>
                                )}

                                {craft?.reward && (
                                    <div className='info-label'>
                                        Reward Rate:
                                        <div className='info-description'>
                                            {craft?.reward}
                                        </div>
                                    </div>
                                )}
                                {craft?.required_claims >= 0 && (
                                    <div className='info-label'>
                                        Required Builds:
                                        <div className='info-description'>
                                            {craft.required_claims}
                                        </div>
                                    </div>
                                )}
                                {craft?.num_slots >= 0 && (
                                    <div className='info-label'>
                                        Number of Slots:
                                        <div className='info-description'>
                                            {craft.num_slots}
                                        </div>
                                    </div>
                                )}
                                {craft?.charged_time >= 0 && (
                                    <div className='info-label'>
                                        Charge Time:
                                        <div className='info-description'>
                                            {craft.charged_time / 60 > 60
                                                ? craft.charged_time / 3600 +
                                                  ' hours'
                                                : parseFloat(
                                                      craft.charged_time / 60
                                                  ).toFixed() + ' mins' || null}
                                        </div>
                                    </div>
                                )}

                                {craft?.energy_consumed >= 0 && (
                                    <div className='info-label'>
                                        Energy Consumed:
                                        <div className='info-description'>
                                            {craft?.energy_consumed || 0}
                                        </div>
                                    </div>
                                )}
                                {craft?.durability_consumed && (
                                    <div className='info-label'>
                                        Durability Consumed:
                                        <div className='info-description'>
                                            {craft?.durability_consumed || null}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='forge__resource-section'>
                            <div className='forge__resource--tilte '>COST</div>
                            <div className='forge__resource-container'>
                                <div className='forge__resource-group'>
                                    <img
                                        src='./img/plain-gold-icon.png'
                                        alt='GOLD'
                                        className='forge__resource--image'
                                    />
                                    <div
                                        className={
                                            'forge__resource--text ' +
                                            (balances?.gold >=
                                            (craft?.gold_mint ||
                                                craft?.golds_mint)
                                                ? ''
                                                : 'fail')
                                        }>
                                        {craft?.gold_mint ||
                                            craft?.golds_mint ||
                                            0}
                                    </div>
                                </div>
                                {craft?.wood_mint && (
                                    <div className='forge__resource-group'>
                                        <img
                                            src='./img/plain-wood-icon.png'
                                            alt='WOOD'
                                            className='forge__resource--image'
                                            style={{
                                                transform: 'rotate(-2.27deg)'
                                            }}
                                        />
                                        <div
                                            className={
                                                'forge__resource--text ' +
                                                (balances?.wood >=
                                                craft?.wood_mint
                                                    ? ''
                                                    : 'fail')
                                            }>
                                            {craft?.wood_mint || 0}
                                        </div>
                                    </div>
                                )}
                                {craft?.coins_mint && (
                                    <div className='forge__resource-group'>
                                        <img
                                            src='./img/Farmer-coin.png'
                                            height={40}
                                            width={40}
                                            alt='COIN'
                                            className='forge__resource--image'
                                        />
                                        <div
                                            className={
                                                'forge__resource--text ' +
                                                (totalCoin >= craft?.coins_mint
                                                    ? ''
                                                    : 'fail')
                                            }>
                                            {craft?.coins_mint || 0}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {craft?.wood_mint && (
                            <TicketInput
                                ticket={ticket}
                                setTicket={setTicket}
                                ticketsChest={ticketsChest}
                            />
                        )}
                        <div className='smithy-forge--button tooltip'>
                            <Button
                                text='Craft'
                                atr='long'
                                isDisabled={isCraftable}
                                wrapperClassname='full-width'
                                handleClick={HandleCraft}
                            />
                            {isCraftable === 'disabled' && (
                                <span className='tooltiptext tooltip-bottom'>
                                    <i className='arrow-up'></i>Look like you
                                    don't have enough resources
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
