/** @format */

import { createSlice } from "@reduxjs/toolkit";

export const NavBarSlice = createSlice({
	name: "navBar",
	initialState: {
		tabs: [
			{
				name: "Home",
				icon: "/Home.png",
			},
			{
				name: "Chest",
				icon: "/Chest.png",
			},
			{
				name: "Smithy",
				icon: "/Smithy.png",
			},
			{
				name: "Exchange",
				icon: "/Exchange.png",
			},
			{
				name: "Map",
				icon: "/Map.png",
			},
			{
				name: "Market",
				icon: "/Market.png",
			},
			{
				name: "Atomic",
				icon: "/Shop.png",
			},
		],
		selectedTab: 0,
	},
	reducers: {
		ChooseTab: (state, action) => {
			state.selectedTab = action.payload;
		},
	},
});

export const { ChooseTab } = NavBarSlice.actions;

export default NavBarSlice.reducer;
