/** @format */

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectTabData, deposit, resetChange } from './exchangeSlice';
import { setErrorMessage, toggleModal } from '../../redux/slice/modalSlice';

import ExchangeInput from './ExchangeInput';

import Button from '../../components/Button';
import ExchangeBalance from './ExchangeBalance';
import { cancelLoading, setUpdate } from '../../redux/slice/GameSlicer';

import plainFood from '../../assets/img/plain-meat.png';
import plainWood from '../../assets/img/plain-wood-icon.png';
import plainGold from '../../assets/img/plain-gold-icon.png';
import goldTypeIcon from '../../assets/img/GOLD-dark-type-icon.svg';
import woodTypeIcon from '../../assets/img/WOOD-dark-type-icon.svg';
import foodTypeIcon from '../../assets/img/FOOD-dark-type-icon.svg';
import getErrorMessages from '../../utils/getErrorMessages';
import left from '../../assets/img/prev.png';
import right from '../../assets/img/next.png';
import WithdrawCoin from './WithdrawCoin';
const exchangeIcons = [
    plainGold,
    plainWood,
    plainFood,
    goldTypeIcon,
    woodTypeIcon,
    foodTypeIcon
];
export default function DepositTab() {
    const tokens = useSelector((state) => state.exchange.tokens);
    const exchangeData = useSelector((state) => selectTabData(state.exchange));
    const [isWithdrawCoin, setWithdrawCoin] = useState(false);

    const isDisabled =
        exchangeData.wood > 0 || exchangeData.gold > 0 || exchangeData.food > 0
            ? null
            : 'disabled';
    const isZeroTokens = !(
        tokens?.FWF > 0 ||
        tokens?.FWW > 0 ||
        tokens?.FWG > 0
    );
    const dispatch = useDispatch();
    const handleDeposit = async () => {
        try {
            dispatch(cancelLoading(false));

            if (isDisabled !== 'disabled') {
                const resultAction = await dispatch(
                    deposit({ ...exchangeData })
                ).unwrap();
                if (resultAction?.transaction_id !== null) {
                    dispatch(setUpdate(true));
                    dispatch(
                        setErrorMessage('Transaction completed successfully')
                    );
                    dispatch(toggleModal(true));
                    dispatch(resetChange());
                }
            } else {
                if (isZeroTokens)
                    dispatch(
                        setErrorMessage(
                            'Seem like you dont have any tokens to deposit.'
                        )
                    );
                else
                    dispatch(
                        setErrorMessage('You should enter positive values')
                    );
                dispatch(toggleModal(true));
            }
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
            dispatch(setUpdate(true));
        }
    };
    return (
        <div className='withdrawTab-container'>
            {isWithdrawCoin ? (
                <WithdrawCoin isWithdrawCoin={false} />
            ) : (
                <div className='exchange-container'>
                    <div className='exchange-content'>
                        <div className='exchange-group'>
                            <div className='exchange-content-title'>Tokens</div>
                            <ExchangeBalance
                                resource={tokens?.FWG}
                                image={exchangeIcons[3]}
                                type={'FWG'}
                            />
                            <ExchangeBalance
                                resource={tokens?.FWW}
                                image={exchangeIcons[4]}
                                type={'FWW'}
                            />
                            <ExchangeBalance
                                resource={tokens?.FWF}
                                image={exchangeIcons[5]}
                                type={'FWF'}
                            />
                        </div>
                        <div className='exchange-group'>
                            <div className='exchange-content-title'>
                                Deposit Amount
                            </div>
                            <ExchangeInput
                                exchangeValue={exchangeData.gold}
                                initialResource={tokens?.FWG}
                                image={exchangeIcons[3]}
                                resource='FWG'
                                type='straight'
                            />
                            <ExchangeInput
                                exchangeValue={exchangeData.wood}
                                initialResource={tokens?.FWW}
                                image={exchangeIcons[4]}
                                resource='FWW'
                                type='straight'
                            />
                            <ExchangeInput
                                exchangeValue={exchangeData.food}
                                initialResource={tokens?.FWF}
                                image={exchangeIcons[5]}
                                resource='FWF'
                                type='straight'
                            />
                        </div>
                        <div className='exchange-group'>
                            <div className='exchange-content-title'>
                                Total Resource
                            </div>
                            <ExchangeInput
                                exchangeValue={exchangeData.gold}
                                initialResource={tokens?.FWG}
                                image={exchangeIcons[0]}
                                resource='gold'
                                type='straight'
                            />
                            <ExchangeInput
                                exchangeValue={exchangeData.wood}
                                initialResource={tokens?.FWW}
                                image={exchangeIcons[1]}
                                resource='wood'
                                type='straight'
                            />
                            <ExchangeInput
                                exchangeValue={exchangeData.food}
                                initialResource={tokens?.FWF}
                                image={exchangeIcons[2]}
                                resource='food'
                                type='straight'
                            />
                        </div>
                    </div>
                    <div className='withdraw-button tooltip'>
                        <Button
                            text='Deposit'
                            handleClick={handleDeposit}
                            atr='long small'
                            isDisabled={isDisabled}
                            wrapperClassname='full-width'
                        />

                        {isDisabled === 'disabled' && (
                            <span className='tooltiptext tooltip-bottom'>
                                <i className='arrow-up'></i>You have to enter
                                Deposit amount{' '}
                            </span>
                        )}
                        {/* <span className="tooltiptext tooltip-bottom"><i className="arrow-up"></i>You have to enter Deposit amount to Deposit </span> */}
                    </div>
                </div>
            )}
            <button
                onClick={() => setWithdrawCoin(!isWithdrawCoin)}
                className={
                    isWithdrawCoin
                        ? 'withdrawTab-nav left'
                        : 'withdrawTab-nav right'
                }>
                {!isWithdrawCoin && 'Coin'}
                <img
                    src={isWithdrawCoin ? left : right}
                    alt='Nav'
                    className='withdraw__nav--img'
                />
                {isWithdrawCoin && 'Token'}
            </button>
        </div>
    );
}
