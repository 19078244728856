/** @format */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { game } from './authSlicer';
import { coinScheme } from '../../config';
export const getCoin = createAsyncThunk('coins/getCoin', async () => {
    return await game.getItemsBySchema(coinScheme.main, 122);
});
export const getTotalCoin = createAsyncThunk('coins/getTotalCoin', async () => {
    const itemList = await game.countAssetBySchema(coinScheme.main);
    return itemList;
});
export const getStakedCoin = createAsyncThunk(
    'coins/getStakedCoin',
    async () => {
        return await game.getStakedCoin();
    }
);

export const withdrawStakedCoin = createAsyncThunk(
    'coins/withdrawStakedCoin',
    async (amount) => {
        const response = await game.withdrawStakedCoin(amount);
        return response;
    }
);
export const depositCoin = createAsyncThunk(
    'coins/depositCoin',
    async (amount, { getState }) => {
        const { coin } = getState();
        const response = await game.depositCoin(
            coin.coinsId.slice(0, amount <= 120 ? amount : 120)
        );
        return response;
    }
);
export const sendCoins = createAsyncThunk(
    'coins/sendCoins',
    async ({ to, amount }, { getState }) => {
        const response = await game.sendCoins({ to, amount });
        return response;
    }
);
export const CoinSlice = createSlice({
    name: 'coins',
    initialState: {
        coinConfig: {
            total: 0,
            isWearable: false
        },
        stakedCoin: 0,
        coinsId: [],
        totalCoin: 0,
        status: 'idle',
        error: ''
    },
    reducers: {
        getCard: (state, action) => {}
    },
    extraReducers: (builder) => {
        builder

            .addCase(getCoin.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getCoin.fulfilled, (state, action) => {
                state.status = 'loaded';
                let temp = state.coinConfig.total;
                state.coinConfig = action.payload[0] || {};
                if (state.coinConfig.asset_id) {
                    state.coinConfig.isWearable = false;
                    state.coinConfig.total = temp;
                    state.coinsId = action.payload.map((item) => item.asset_id);
                } else {
                    state.coinConfig = [];
                }
            })
            .addCase(getCoin.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(depositCoin.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(depositCoin.fulfilled, (state, action) => {
                state.status = 'loaded';
                console.log('depositCoin fulfilled', action.payload);
            })
            .addCase(depositCoin.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(withdrawStakedCoin.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(withdrawStakedCoin.fulfilled, (state, action) => {
                state.status = 'loaded';
                console.log('withdrawStakedCoin fulfilled', action.payload);
            })
            .addCase(withdrawStakedCoin.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getStakedCoin.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getStakedCoin.fulfilled, (state, action) => {
                console.log('getStakedCoin.fulfilled', action.payload);
                state.stakedCoin = action.payload[0]?.amount || 0;
                state.status = 'loaded';
            })
            .addCase(getStakedCoin.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getTotalCoin.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getTotalCoin.fulfilled, (state, action) => {
                state.status = 'loaded';
                console.log('getTotalCoin.fulfilled', action.payload);
                if (!Array.isArray(state.coinConfig)) {
                    state.coinConfig.total = action.payload;
                    state.totalCoin = action.payload;
                }
            })
            .addCase(getTotalCoin.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});
export const { getCard } = CoinSlice.actions;

export default CoinSlice.reducer;
