export const imgList = [
    './img/anchor.png',
    './img/arrow-bar.png',
    './img/board-layout.png',
    './img/border-button.png',
    './img/card-overlay.png',
    './img/chicken-background.jpg',
    './img/chicken-map.jpg',
    './img/close-button.png',
    './img/cow-female.png',
    './img/cow-male.png',
    './img/cow-map.jpg',
    './img/cows-bg.jpg',
    './img/crop-map.jpg',
    './img/crops-bg.jpg',
    './img/down.png',
    './img/dropdown.svg',
    './img/egg-nest.png',
    './img/energy-icon.png',
    './img/Farmer-coin.png',
    './img/gold-icon.png',
    './img/GOLD-type-icon.svg',
    './img/home-background.jpg',
    './img/home-map-bg.jpg',
    './img/chicken-map.jpg',
    './img/cows-bg.jpg',
    './img/crops-bg.jpg',
    './img/login-background.jpg',
    './img/long-arrow-bar.png',
    './img/meat-icon.png',
    './img/minus.png',
    './img/next.png',
    './img/noitems.png',
    './img/note-icon.png',
    './img/paper.png',
    './img/plain-button.png',
    './img/plain-energy.png',
    './img/plain-gold-icon.png',
    './img/plain-meat.png',
    './img/plain-wood-icon.png',
    './img/plus.png',
    './img/prev.png',
    './img/small-paper.png',
    './img/small-plain-button.png',
    './img/small-thumb.png',
    './img/tall-card-border.png',
    './img/thumb.png',
    './img/time-border.png',
    './img/track.png',
    './img/up.png',
    './img/vertical-track.png',
    './img/wax-primary-logo.png',
    './img/WOOD-dark-type-icon.svg',
    './img/wood-icon.png',
    './img/WOOD-type-icon.svg'
];
