/** @format */

import React from 'react';
import { setErrorMessage, toggleModal } from '../../redux/slice/modalSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectTabData,
    withdraw,
    resetChange,
    getConfigs
} from './exchangeSlice';
import { cancelLoading, setUpdate } from '../../redux/slice/GameSlicer';

import ExchangeInput from './ExchangeInput';
import Button from '../../components/Button';
import ExchangeBalance from './ExchangeBalance';

import plainFood from '../../assets/img/plain-meat.png';
import plainWood from '../../assets/img/plain-wood-icon.png';
import plainGold from '../../assets/img/plain-gold-icon.png';
import goldTypeIcon from '../../assets/img/GOLD-dark-type-icon.svg';
import woodTypeIcon from '../../assets/img/WOOD-dark-type-icon.svg';
import foodTypeIcon from '../../assets/img/FOOD-dark-type-icon.svg';
import getErrorMessages from '../../utils/getErrorMessages';

const exchangeIcons = [
    plainGold,
    plainWood,
    plainFood,
    goldTypeIcon,
    woodTypeIcon,
    foodTypeIcon
];
export default function WithdrawToken() {
    const balances = useSelector((state) => state.user.balances);
    const exchangeData = useSelector((state) => selectTabData(state.exchange));
    const tax = useSelector((state) => state.exchange.tax);
    const isDisabled =
        exchangeData.wood > 0 || exchangeData.gold > 0 || exchangeData.food > 0
            ? null
            : 'disabled';

    const dispatch = useDispatch();
    const handleWithdraw = async () => {
        await dispatch(getConfigs()).unwrap();
        try {
            dispatch(cancelLoading(false));

            if (isDisabled !== 'disabled') {
                const resultAction = await dispatch(
                    withdraw({ ...exchangeData, fee: tax })
                ).unwrap();
                if (resultAction?.transaction_id !== null) {
                    dispatch(setUpdate(true));
                    dispatch(
                        setErrorMessage('Transaction completed successfully')
                    );
                    dispatch(toggleModal(true));
                    dispatch(resetChange());
                }
            } else {
                dispatch(
                    setErrorMessage('Oh you want to withdraw nothing? No way!')
                );
                dispatch(toggleModal(true));
            }
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
            dispatch(setUpdate(true));
        }
    };

    return (
        <>
            <div className='withdraw-tax'>
                <div className='withdraw-tax-tag'>Fee: {tax}%</div>
                <div className='withdraw-tax-text'>
                    {/* New fee in {timeStr} */}
                    Fee will be updated every hour
                </div>
            </div>
            <div className='exchange-container'>
                <div className='exchange-content'>
                    <div className='exchange-group'>
                        <div className='exchange-content-title'>Balances</div>
                        <ExchangeBalance
                            resource={balances?.gold}
                            image={exchangeIcons[0]}
                        />
                        <ExchangeBalance
                            resource={balances?.wood}
                            image={exchangeIcons[1]}
                        />
                        <ExchangeBalance
                            resource={balances?.food}
                            image={exchangeIcons[2]}
                        />
                    </div>
                    <div className='exchange-group'>
                        <div className='exchange-content-title'>
                            Withdrawal Amount
                        </div>
                        <ExchangeInput
                            exchangeValue={exchangeData.gold}
                            initialResource={balances?.gold || 0}
                            image={exchangeIcons[0]}
                            resource='gold'
                            type='straight'
                        />
                        <ExchangeInput
                            exchangeValue={exchangeData.wood}
                            initialResource={balances?.wood || 0}
                            image={exchangeIcons[1]}
                            resource='wood'
                            type='straight'
                        />
                        <ExchangeInput
                            exchangeValue={exchangeData.food}
                            initialResource={balances?.food || 0}
                            image={exchangeIcons[2]}
                            resource='food'
                            type='straight'
                        />
                    </div>
                    <div className='exchange-group'>
                        <div className='exchange-content-title'>
                            Total Token
                        </div>
                        <ExchangeInput
                            exchangeValue={exchangeData.gold}
                            initialResource={balances?.gold || 0}
                            image={exchangeIcons[3]}
                            resource='FWG'
                            type='reverse'
                        />
                        <ExchangeInput
                            exchangeValue={exchangeData.wood}
                            initialResource={balances?.wood || 0}
                            image={exchangeIcons[4]}
                            resource='FWW'
                            type='reverse'
                        />
                        <ExchangeInput
                            exchangeValue={exchangeData.food}
                            initialResource={balances?.food || 0}
                            image={exchangeIcons[5]}
                            resource='FWF'
                            type='reverse'
                        />
                    </div>
                </div>
                <div className='withdraw-button tooltip'>
                    <Button
                        text='Withdraw'
                        handleClick={handleWithdraw}
                        atr='long small'
                        isDisabled={isDisabled}
                        wrapperClassname='full-width'
                    />
                    {isDisabled === 'disabled' && (
                        <span className='tooltiptext tooltip-bottom'>
                            <i className='arrow-up'></i>Please Enter Withdraw
                            Amount!
                        </span>
                    )}
                </div>
            </div>
        </>
    );
}
