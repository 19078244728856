/** @format */

import React, { useEffect } from 'react';
import './index.scss';
import { useSelector, useDispatch } from 'react-redux';

import DepositTab from './DepositTab';
import WithdrawTab from './WithdrawTab';
import { chooseTab } from './exchangeSlice';
import { getConfigs, getTokens } from './exchangeSlice';
import { getPlayerInfo } from '../../redux/slice/userSlicer';
import TransferTab from './TransferTab';
export default function Exchange() {
    const selectedTab = useSelector((state) => state.exchange.selectedTab);

    const dispatch = useDispatch();
    useEffect(() => {
        const update = setInterval(async () => {
            await dispatch(getConfigs()).unwrap();
            await dispatch(getTokens()).unwrap();
            await dispatch(getPlayerInfo()).unwrap();
        }, 5000);
        return () => clearInterval(update);
    });

    return (
        <section className='home-container'>
            <div className='home-content'>
                <div className='exchange-navbar'>
                    <div
                        style={{
                            backgroundImage: 'url(./img/small-paper.png)'
                        }}
                        className={
                            'exchange-navbar--item ' +
                            (selectedTab === 2 && 'selected')
                        }
                        onClick={() => dispatch(chooseTab(2))}>
                        Transfer
                    </div>
                    <div
                        style={{
                            backgroundImage: 'url(./img/small-paper.png)'
                        }}
                        className={
                            'exchange-navbar--item ' +
                            (selectedTab === 0 && 'selected')
                        }
                        onClick={() => dispatch(chooseTab(0))}>
                        Withdraw
                    </div>
                    <div
                        style={{
                            backgroundImage: 'url(./img/small-paper.png)'
                        }}
                        className={
                            'exchange-navbar--item ' +
                            (selectedTab === 1 && 'selected')
                        }
                        onClick={() => dispatch(chooseTab(1))}>
                        Deposit
                    </div>
                </div>
                {selectedTab === 2 ? (
                    <TransferTab />
                ) : selectedTab === 0 ? (
                    <WithdrawTab />
                ) : (
                    <DepositTab />
                )}
            </div>
        </section>
    );
}
