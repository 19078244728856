/** @format */
import { setLackResource } from '../redux/slice/GameSlicer';
export default function getErrorMessages(
    error,
    dispatch,
    setErrorMessage,
    toggleModal
) {
    try {
        console.error(error);
        const message = JSON.parse(error?.message);
        if (message?.error?.details[0]?.message?.includes('transaction net'))
            dispatch(setLackResource('NET'));
        else if (message?.error?.details[0]?.message?.includes('billed CPU'))
            dispatch(setLackResource('CPU'));
        else {
            dispatch(
                setErrorMessage(
                    message?.error?.details[0]?.message ||
                        error?.message ||
                        error
                )
            );
            dispatch(toggleModal(true));
        }
    } catch (e) {
        if (error?.message?.includes('of undefined'))
            dispatch(
                setErrorMessage(
                    'A moment to relax these hard days! Try again later!'
                )
            );
        else if (error?.message?.includes('to fetch'))
            dispatch(setErrorMessage('Today is a hard day! Try again later!'));
        else if (error?.message?.includes('Aborted due'))
            dispatch(setErrorMessage("Slow down! Don't push too hard!'"));
        else dispatch(setErrorMessage(error?.message || error));
        dispatch(toggleModal(true));
    }
}
