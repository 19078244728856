/** @format */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import {
    atomicUpdate,
    setBackgroundUpdate
} from '../../redux/slice/GameSlicer';
import { toggleModal, setErrorMessage } from '../../redux/slice/modalSlice';
import { useSpring, animated } from 'react-spring';

import { deleteFlash, setFlash } from '../../redux/slice/FlashSlicer';
import getErrorMessages from '../../utils/getErrorMessages';
import CountDown from '../../common/CountDown/CountDown';
import {
    feedAnimal,
    careAnimal,
    getTransaction,
    UpdateTimestamp
} from '../../redux/slice/AnimalsSlicer';
import { getFoodName } from '../../redux/slice/FoodsSlicer';
import { getChestAssetsByTemplate } from '../../redux/slice/AtomicSlicer';
import RemoveBuildsModal from '../../components/Modal/RemoveBuildsModal';
import { UpdateHealth } from '../../redux/slice/userSlicer';
import useWindowSize from '../../utils/useWindowSIze';
import { barleyTemplate } from '../../config';
import { getPicture } from '../../utils';

const min = (a, b) => {
    return a > b ? b : a;
};
function filterDailyClaim(item) {
    if (item + 86400 > Date.now() / 1000) {
        return true;
    }
    return false;
}

export default function AnimalsCard({ data, userEnergy }) {
    const dispatch = useDispatch();
    const isEnergy = userEnergy >= data.energy_consumed;
    // consumed_card
    const [isCountDown, setCountdown] = useState('disabled');
    const [dayClaim, setDayClaim] = useState(
        data.day_claims_at.filter(filterDailyClaim) || []
    );
    const [modalShowing, setModalShowing] = useState(false);

    const [timeClaimLimit, setTimeClaimLimit] = useState(0);
    const calfFood = useSelector((state) =>
        getChestAssetsByTemplate(state.atomic.foods, barleyTemplate.main)
    );
    // 318606
    const isBabyEvolve =
        (data.required_claims === data.times_claimed && calfFood.length) ||
        (!data.name.includes('Baby Calf') && !data.name.includes('Egg'));
    const foodList = useSelector((state) =>
        getChestAssetsByTemplate(state.atomic.foods, data.consumed_card + '')
    );

    const reward = useSelector((state) =>
        getFoodName(state.foods, data.reward_card)
    );
    const isFood =
        (foodList && foodList.length) ||
        ((data.name.includes('Baby Calf') || data.name.includes('Egg')) &&
            isBabyEvolve);
    const isEvolve = data.required_claims === data.times_claimed;
    const actionName = data.name.includes('Egg') ? 'Hatch' : 'Feed';
    const feedText =
        isCountDown === true
            ? isFood || data.consumed_card < 1
                ? isEnergy
                    ? isEvolve
                        ? isBabyEvolve
                            ? 'Evolve'
                            : 'No Barley'
                        : actionName
                    : 'No Energy'
                : 'No Food'
            : 'Countdown';
    const idFeedable =
        (Date.now() - timeClaimLimit * 1000 >= 0 && feedText === actionName) ||
        feedText === 'Evolve'
            ? ''
            : 'disabled';

    const isEnergyRemove = userEnergy >= 200;
    const removeText = isEnergyRemove ? 'Remove' : 'No Energy';
    const isRemovalbe =
        (removeText === 'Remove' && isCountDown === true) || 'disabled';
    // eslint-disable-next-line
    const [viewWidth, viewHeight] = useWindowSize();

    const dubral = useSpring({
        width:
            ((data.times_claimed || 0) / (data.required_claims || 1)) *
            min(viewWidth / 144, viewHeight / 90) *
            16.5,
        backgroundColor: '#B06A38',
        config: { duration: 1000 }
    });
    useEffect(() => {
        if (dayClaim.length === data.daily_claim_limit) {
            setTimeClaimLimit(dayClaim[0] + 86400);
        } else {
            setTimeClaimLimit(0);
        }
        setCountdown(
            Date.now() - data.next_availability * 1000 >= 0 || 'disabled'
        );

        return () => {
            setCountdown(true);
        };
    }, [data.next_availability, data.daily_claim_limit, dayClaim]);

    useEffect(() => {
        setDayClaim(data.day_claims_at.filter(filterDailyClaim));
    }, [data.day_claims_at]);

    const HandleFeed = async () => {
        const flash_id = Date.now();
        const flashMessage = {
            id: flash_id,
            content: `${actionName}ing your ${data.name}`,
            timeout: 30000
        };
        const animal = data.asset_id;

        try {
            let resultAction = {};
            if (idFeedable !== 'disabled') {
                dispatch(setFlash(flashMessage));

                if (data.consumed_card > 0) {
                    if (isBabyEvolve)
                        if (data.name.includes('Calf (Male)') && isEvolve) {
                            resultAction = await dispatch(
                                careAnimal(animal)
                            ).unwrap();
                        } else {
                            resultAction = await dispatch(
                                feedAnimal({ animal: animal, food: calfFood })
                            ).unwrap();
                        }
                    else {
                        resultAction = await dispatch(
                            feedAnimal({ animal: animal, food: foodList })
                        ).unwrap();
                    }
                } else {
                    if (isBabyEvolve)
                        resultAction = await dispatch(
                            feedAnimal({ animal: animal, food: calfFood })
                        ).unwrap();
                    else
                        resultAction = await dispatch(
                            careAnimal(animal)
                        ).unwrap();
                }
                if (!!resultAction.transaction_id) {
                    dispatch(UpdateTimestamp(animal));
                    dispatch(UpdateHealth(data.energy_consumed));
                    const flashMessage2 = {
                        id: flash_id,
                        content: `${actionName}ing your ${data.name} successfully`,
                        timeout: 5000
                    };
                    dispatch(setFlash(flashMessage2));
                    if (
                        data.times_claimed + 1 >= data.required_claims &&
                        data.reward_card
                    ) {
                        const result = await dispatch(
                            getTransaction(resultAction.transaction_id)
                        ).unwrap();
                        if (result.claim) {
                            const inform =
                                "You've just harvested " +
                                result.claim.quantity +
                                ' ' +
                                reward?.name;
                            dispatch(setErrorMessage(inform));
                        } else {
                            dispatch(setErrorMessage('You got your rewards'));
                        }
                        dispatch(toggleModal(true));
                    }
                }
                await dispatch(atomicUpdate()).unwrap();
            }
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
            await dispatch(atomicUpdate()).unwrap();
        } finally {
            dispatch(deleteFlash(flash_id));
            dispatch(setBackgroundUpdate(true));
        }
    };
    const HandleRemove = () => {
        setModalShowing(true);
    };

    return (
        <div className='home__card-container'>
            {modalShowing === true && (
                <RemoveBuildsModal
                    HandleCancel={() => setModalShowing(false)}
                    asset_id={data.asset_id}
                    name={data.name}
                />
            )}
            <div className='card-section'>
                <div className='card-img'>
                    {data.img && (
                        <img
                            src={getPicture(data.img)}
                            onError={(e) => {
                                e.target.onerror = null; // prevents looping
                                e.currentTarget.src = getPicture(data.img);
                            }}
                            alt='card'
                            className='card-img--item'
                        />
                    )}
                </div>

                <div className='card-number'>
                    <animated.div className='fill' style={dubral} />
                    <animated.div className='content'>
                        {data.times_claimed || 0}/ {data.required_claims || 0}
                    </animated.div>
                </div>
            </div>
            <div className='info-section'>
                <div className='info-text__section'>
                    <div className='info-title'>
                        <div
                            className='info-type-icon'
                            style={{
                                backgroundImage: `url(/img/livestock-icon.svg)`
                            }}></div>
                        <div className='info-title-name'>{data.name}</div>
                        {data.daily_claim_limit && (
                            <div className='info-title-level tooltip'>
                                {dayClaim.length}/{data.daily_claim_limit}
                                {data.day_claims_at.length ===
                                    data.daily_claim_limit && (
                                    <span className='tooltiptext tooltip-bottom'>
                                        <CountDown
                                            next_availability={timeClaimLimit}
                                            handleFinish={setCountdown}
                                        />
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    <div className='info-content'>
                        <div className='info-label'>
                            Reward:
                            <div className='info-description'>
                                {reward?.name || 'None'}
                            </div>
                        </div>

                        <div className='info-label'>
                            Charge Time:
                            <div className='info-description'>
                                {data.charge_time / 60 > 60
                                    ? (data.charge_time / 3600).toFixed(0) +
                                      ' hours'
                                    : (data.charge_time / 60).toFixed(0) +
                                          ' mins' || null}
                            </div>
                        </div>
                        <div className='info-label'>
                            Daily Claim Limit
                            <div className='info-description'>
                                {data.daily_claim_limit || 0}
                            </div>
                        </div>
                        <div className='info-label'>
                            Required {actionName}
                            <div className='info-description'>
                                {data.required_claims || 0}
                            </div>
                        </div>
                        <div className='info-label'>
                            Energy Consumed:
                            <div className='info-description'>
                                {data.energy_consumed || 0}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='info-time'>
                    <CountDown
                        next_availability={data.next_availability}
                        handleFinish={setCountdown}
                    />
                </div>
                <div className='home-card-button__group'>
                    {data.required_claims > 0 && (
                        <div className='tooltip home-card-button--item'>
                            <Button
                                className='repair-btn'
                                text={feedText}
                                atr='semi-short'
                                isDisabled={idFeedable}
                                wrapperClassname='set-height'
                                handleClick={HandleFeed}
                            />
                            {!isEnergy && (
                                <span className='tooltiptext tooltip-bottom'>
                                    <i className='arrow-up'></i>Need
                                    {data.energy_consumed} energy to
                                    {actionName}
                                </span>
                            )}
                        </div>
                    )}
                    <div className='tooltip home-card-button--item'>
                        <Button
                            className='repair-btn'
                            text={removeText}
                            atr='semi-short'
                            isDisabled={isRemovalbe}
                            wrapperClassname='set-height'
                            handleClick={HandleRemove}
                        />
                        {!isEnergy && (
                            <span className='tooltiptext tooltip-bottom'>
                                <i className='arrow-up'></i>Need
                                {data.energy_consumed} energy to feed
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
