import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Login from './pages/login';
import HigherGame from './pages/game/index.jsx';
import {
    register,
    setRegisterStatus,
    setLoginStatus,
    checkServersHealth
} from './redux/slice/authSlicer';
import { getPlayerInfo } from './redux/slice/userSlicer';
import Splash from './components/Splash/Splash';
import { toggleModal, setErrorMessage } from './redux/slice/modalSlice';
import LoadingModal from './components/Modal/LoadingModal';
import './App.scss';
import getErrorMessages from './utils/getErrorMessages';
import StakeModal from './components/Modal/StakeModal';

const background = [
    './img/home-background.jpg',
    './img/chicken-background.jpg',
    './img/crops-bg.jpg',
    './img/cows-bg.jpg'
];

export default function App() {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const isRegisteredStatus = useSelector(
        (state) => state.auth.isRegisteredStatus
    );
    const selectedMap = useSelector((state) => state.game.selectedMap);
    const lackingResource = useSelector((state) => state.game.lackingResource);
    const dispatch = useDispatch();

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const referral = params.get('ref');
        const oldReferral = localStorage.getItem('referral');
        if (!oldReferral) localStorage.setItem('referral', referral || '');
    }, []);
    useEffect(() => {
        const handleRegister = async () => {
            try {
                const resultAction = await dispatch(getPlayerInfo()).unwrap();
                if (resultAction.length) {
                    dispatch(setRegisterStatus(true));
                    dispatch(setLoginStatus(true));
                } else {
                    await dispatch(register()).unwrap();
                    dispatch(setRegisterStatus(true));
                    dispatch(setLoginStatus(true));
                }
            } catch (error) {
                getErrorMessages(error, dispatch, setErrorMessage, toggleModal);

                dispatch(setLoginStatus(false));
                throw error;
            }
        };

        if (isLoggedIn && !isRegisteredStatus) handleRegister();
    }, [isLoggedIn, isRegisteredStatus, dispatch]);

    useLayoutEffect(() => {
        dispatch(checkServersHealth());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className='body-container'
            style={{
                background: `${
                    isLoggedIn && isRegisteredStatus
                        ? `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)) no-repeat top, url(${background[selectedMap]}) no-repeat top`
                        : 'white'
                }`
            }}>
            {isLoggedIn && isRegisteredStatus ? (
                <React.Fragment>
                    <Splash />
                    <HigherGame background={background[selectedMap]} />
                </React.Fragment>
            ) : (
                <Login />
            )}
            <LoadingModal />
            {lackingResource !== '' && (
                <StakeModal lackingResource={lackingResource} />
            )}
        </div>
    );
}
