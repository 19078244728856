/** @format */

import React, { useState, useLayoutEffect } from 'react';
import './index.scss';
import { useSelector, useDispatch } from 'react-redux';
import { chooseUsingCard } from '../../redux/slice/ToolsSlicer';
import CardList from '../../components/CardList/CardList';
import CardController from './CardController';
import EmptyTab from '../../components/EmptyTab';

export default function Home() {
    const selectedMap = useSelector((state) => state.game.selectedMap);
    const usingCardsList = useSelector((state) => state.tools.usingItems);
    const usingBadges = useSelector((state) => state.badge.usingBadges);
    const cowUsing = useSelector((state) => state.animals.cowUsing);
    const chickenUsing = useSelector((state) => state.animals.chickenUsing);
    const plantsUsing = useSelector((state) => state.plants.plantsUsing);
    const userEnergy = useSelector((state) => state.user.info.energy);
    const gold = useSelector((state) => state.user.balances.gold);
    const selectedUsingCard = useSelector(
        (state) => state.tools.selectedUsingCard
    );
    const [usingItems, setUsingItems] = useState([]);
    const [isPlayable, setIsPlayable] = useState(false);
    const dispatch = useDispatch();

    const HandleChoose = (index) => {
        dispatch(chooseUsingCard(index));
    };

    useLayoutEffect(() => {
        const HanldeDataMapping = () => {
            switch (selectedMap) {
                case 0:
                    return usingCardsList.concat(usingBadges);
                case 1:
                    return chickenUsing;

                case 2:
                    return plantsUsing;
                case 3:
                    return cowUsing;
                default:
                    return [];
            }
        };

        setUsingItems(HanldeDataMapping());
        setIsPlayable(!!usingItems.length);

        return () => {};
    }, [
        chickenUsing,
        cowUsing,
        plantsUsing,
        selectedMap,
        usingBadges,
        usingCardsList,
        usingItems.length
    ]);

    // const usingItems = HanldeDataMapping();
    // const selectedCardMapping = selectedUsingCard;

    const storeMiningGold = usingItems.reduce((storeMiningGolds, usingItem) => {
        if (usingItem.saved_claims && usingItem.type === 'Gold') {
            return (storeMiningGolds += usingItem.saved_claims);
        }
        return storeMiningGolds;
    }, 0);
    const storeMiningFood = usingItems.reduce((storeMiningFoods, usingItem) => {
        if (usingItem.saved_claims && usingItem.type === 'Food') {
            storeMiningFoods += usingItem.saved_claims;
        }
        return storeMiningFoods;
    }, 0);

    const storeMiningWood = usingItems.reduce((storeMiningWoods, usingItem) => {
        if (usingItem.saved_claims && usingItem.type === 'Wood') {
            storeMiningWoods += usingItem.saved_claims;
        }
        return storeMiningWoods;
    }, 0);
    const storeMining = [storeMiningGold, storeMiningFood, storeMiningWood];

    return isPlayable ? (
        <section className='home-container'>
            <div className='home-content'>
                <CardList
                    data={usingItems}
                    selected={selectedUsingCard % (usingItems.length || 1)}
                    HandleChoose={HandleChoose}
                />
                <CardController
                    data={
                        usingItems[selectedUsingCard % (usingItems.length || 1)]
                    }
                    userEnergy={userEnergy}
                    gold={gold}
                    storeMining={storeMining}
                />
            </div>
        </section>
    ) : (
        <EmptyTab />
    );
}
