/** @format */

import React from 'react';
// import './index.scss'
import ResourceBar from './resourceBar';

import wood from '../../assets/img/wood-icon.png';
import gold from '../../assets/img/gold-icon.png';
import food from '../../assets/img/meat-icon.png';
import energy from '../../assets/img/energy-icon.png';

import { useSelector } from 'react-redux';
import { selectUserInfo, selectBalances } from '../../redux/slice/userSlicer';
const header = ['MINE', 'CHICKEN', 'CROP', 'COW'];
export default function HeaderBar() {
    const resourceImg = [
        {
            name: 'GOLD',
            icon: gold
        },
        {
            name: 'WOOD',
            icon: wood
        },
        {
            name: 'FOOD',
            icon: food
        },
        {
            name: 'energy',
            icon: energy
        }
    ];

    const index = useSelector((state) => state.navBar.selectedTab);
    const title = useSelector((state) => state.navBar.tabs[index]);
    const balances = useSelector(selectBalances);
    const userInfo = useSelector(selectUserInfo);
    const selectedMap = useSelector((state) => state.game.selectedMap);
    return (
        <section className='container__header'>
            <ResourceBar {...resourceImg[0]} amount={balances.gold || 0} />
            <ResourceBar {...resourceImg[1]} amount={balances.wood || 0} />
            <div
                style={{ backgroundImage: 'url(./img/border-button.png)' }}
                className='container__header--tilte'>
                {title?.name || header[selectedMap]}
            </div>
            <ResourceBar {...resourceImg[2]} amount={balances.food || 0} />

            <ResourceBar {...resourceImg[3]} {...(userInfo || 0)} />
        </section>
    );
}
