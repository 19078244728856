import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../components/Button';

import farmersCoin from '../../assets/img/Farmer-coin.png';
import Coinbalance from './CoinBalance';

import styles from './WithdrawCoin.module.scss';
import ExchangeInput from './ExchangeInput';
import classnames from 'classnames';
import getErrorMessages from '../../utils/getErrorMessages';
import { setUpdate } from '../../redux/slice/GameSlicer';
import { setErrorMessage, toggleModal } from '../../redux/slice/modalSlice';
import {
    withdrawStakedCoin,
    getStakedCoin,
    depositCoin,
    getTotalCoin
} from '../../redux/slice/CoinSlicer';

export default function WithdrawCoin({ isWithdrawCoin }) {
    const atomicCoin = useSelector((state) => state.coin.totalCoin);
    const stakedCoin = useSelector((state) => state.coin.stakedCoin);
    const [isDisabled, setDisabled] = useState(false);
    const [coin, setCoin] = useState(0);
    const dispatch = useDispatch();
    const handleWithdraw = async () => {
        try {
            if (isWithdrawCoin) {
                const response = await dispatch(
                    withdrawStakedCoin(coin)
                ).unwrap();
                if (!!response.transaction_id) {
                    dispatch(setUpdate(true));
                    dispatch(
                        setErrorMessage('Transaction completed successfully')
                    );
                }
            } else {
                const response = await dispatch(depositCoin(coin)).unwrap();
                if (!!response.transaction_id) {
                    dispatch(setUpdate(true));
                    dispatch(
                        setErrorMessage('Transaction completed successfully')
                    );
                }
                await dispatch(getTotalCoin()).unwrap();
            }
        } catch (error) {
            getErrorMessages(error, dispatch, setErrorMessage, toggleModal);
            dispatch(setUpdate(true));
        }
        await dispatch(getStakedCoin()).unwrap();
    };
    useEffect(() => {
        if (coin === 0) setDisabled(true);
    }, [coin]);
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                You have
                <Coinbalance amount={atomicCoin} img={farmersCoin} />
                in your AtomicHub
            </div>
            <div className={styles.group}>
                <div className={styles.title}>Ingame Coins</div>
                <div className={classnames(styles.content, styles.full)}>
                    <Coinbalance amount={stakedCoin} img={farmersCoin} />
                </div>
            </div>
            <div className={styles.group}>
                <div className={styles.title}>
                    {isWithdrawCoin ? 'Withdrawal Amount' : 'Deposit Amount'}
                </div>
                <div className={styles.content}>
                    <ExchangeInput
                        type='straight'
                        exchangeValue={coin}
                        initialResource={
                            isWithdrawCoin ? stakedCoin : atomicCoin
                        }
                        image={farmersCoin}
                        resource={'Coins'}
                        setCoin={setCoin}
                    />
                </div>
            </div>
            <div className='withdraw-button tooltip'>
                <Button
                    text={isWithdrawCoin ? 'Withdraw' : 'Deposit'}
                    handleClick={handleWithdraw}
                    atr='semi-short '
                    isDisabled={isDisabled}
                    wrapperClassname='half-width'
                    setCoin={setCoin}
                />
                {isDisabled === 'disabled' && (
                    <span className='tooltiptext tooltip-bottom'>
                        <i className='arrow-up'></i>Please Enter
                        {isWithdrawCoin ? 'Withdraw' : 'Deposit'}
                        Amount!
                    </span>
                )}
            </div>
        </div>
    );
}
