/** @format */

import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Button from '../Button';

const HappyModal = ({ HandleCancel }) => {
    const refOutside = useRef(null);
    const handleClickOutside = (event) => {
        if (refOutside.current && !refOutside.current.contains(event.target)) {
            HandleCancel();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });
    const handleDispatch = async () => {
        window.open('https://happy.farmersworld.io', '_blank').focus();
        HandleCancel();
    };
    return ReactDOM.createPortal(
        <React.Fragment>
            <div className='modal-wrapper' tabIndex={-1} role='dialog'>
                <div className='modal' ref={refOutside}>
                    <div className='modal-content mid'>
                        Happy Farmers World is not available on Android
                        browsers, IOS browsers and Safari.
                    </div>
                    <div className='modal__button-group token'>
                        <Button
                            type='button'
                            atr='short'
                            wrapperClassname='full-width'
                            data-dismiss='modal'
                            text='Accept'
                            handleClick={handleDispatch}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>,
        document.body
    );
};
export default HappyModal;
