/** @format */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isRepeatable, sleep } from '../../utils';

import { game } from './authSlicer';

//import { idList } from './list';
import { MAIN_SERVERURL, coinTemplate } from '../../config';
import { timeout } from '../../utils';

export const getRefundItem = createAsyncThunk(
    'atomic/getRefundItem',
    async () => {
        return await game.getRefundItem();
    }
);

export const getRefund = createAsyncThunk(
    'atomic/getRefund',
    async (x, { getState }) => {
        for (let count = 0; count < 3; count++) {
            try {
                const { atomic } = getState();

                const response = await game.getRefund(
                    atomic.refundChest['idList'].chunk_inefficient(5)
                );
                return response;
            } catch (error) {
                if (isRepeatable(error.message) && count < 2) continue;
                throw error;
            }
        }
    }
);

export const getExchangeTransactions = createAsyncThunk(
    'atomic/getExchangeTransactions',
    async () => {
        let response = {};
        let SERVER_URL = [...MAIN_SERVERURL];
        let result = [];
        let idList = [];
        for (let index in idList) {
            let transaction_id = idList[index];
            for (let count = 0; count < MAIN_SERVERURL.length * 5; count++) {
                try {
                    response = await timeout(
                        game.getTransaction(
                            transaction_id,
                            SERVER_URL[count % SERVER_URL.length]
                        ),
                        2000,
                        null
                    );
                    if (response.executed !== true) continue;
                    if (response.actions) {
                        let burn = '';
                        response.actions.forEach((action) => {
                            if (action.act.name === 'logburnrs') {
                                burn = action.act.data?.rewards || 0;
                                return;
                            }
                        });
                        result.push(burn);
                        break;
                    }
                } catch (error) {
                    SERVER_URL.splice(count % SERVER_URL.length, 1);
                    count--;
                    if (SERVER_URL.length === 0)
                        throw new Error(`Cannot display what you got!`);

                    continue;
                }
            }
        }
    }
);

export const getAtomicChest = createAsyncThunk(
    'atomic/getAtomicChest',
    async () => {
        await sleep(3000);
        return await game.getItems(coinTemplate.main);
    }
);
export const getCoin = createAsyncThunk('atomic/getCoin', async () => {
    return await game.getTemplaasds();
});

const canWear = ['plants', 'tools', 'farmbuilding'];

export const getChestAssetsByTemplate = (state, template_id) => {
    const assets = state.filter(
        (food) => food.template.template_id === template_id + ''
    );
    return assets[0]?.idList || [];
};
export const getFirstBuilding = (state, template_id) =>
    state.farmbuilding?.find(
        (building) => building.template?.template_id + '' === template_id
    );
export const atomicSlice = createSlice({
    name: 'atomic',
    initialState: {
        plants: [],
        memberships: [],
        farmanimals: [],
        tools: [],
        packs: [],
        farmercoins: [],
        farmbuilding: [],
        foods: [],
        refundConf: [],
        tickets: [],
        refundChest: {
            idList: []
        },
        stakedCoin: 0,
        refundAmount: 0,
        status: 'idle',
        response: '',
        error: ''
    },
    reducers: {
        popAssets: (state, action) => {
            const { card, total } = action.payload;
            const [...temp] = state[card.schema.schema_name];
            temp.forEach((obj, i) => {
                if (obj.template.template_id === card.template.template_id) {
                    const tempIdList = card.idList.slice(total);
                    obj.idList = tempIdList;
                    obj.total = obj.total - total;
                }
            });
            state[card.schema.schema_name] = temp;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAtomicChest.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getAtomicChest.fulfilled, (state, action) => {
                let tempSchema = [];
                let tempChest = {};
                let tempRefund = [];
                state.refundChest = [];
                state.plants = [];
                state.memberships = [];
                state.farmanimals = [];
                state.tools = [];
                state.packs = [];
                state.farmercoins = [];
                state.farmbuilding = [];
                state.foods = [];
                action.payload.forEach((payload) => {
                    if (state.refundConf.includes(payload.asset_id)) {
                        state.refundChest = payload;
                        tempRefund.push(payload.asset_id);
                        state.refundChest['isRefundable'] = true;
                        return;
                    }
                    if (!tempChest[payload.schema.schema_name]) {
                        tempChest[payload.schema.schema_name] = {};
                    }
                    if (
                        !tempChest[payload.schema.schema_name][
                            payload.template.template_id
                        ]
                    ) {
                        tempChest[payload.schema.schema_name][
                            payload.template.template_id
                        ] = Object.assign({}, payload);
                        if (canWear.includes(payload.schema.schema_name)) {
                            tempChest[payload.schema.schema_name][
                                payload.template.template_id
                            ]['isWearable'] = true;
                        }
                        if (payload.data.unpack) {
                            if (
                                payload.data.unpack.toLowerCase() === 'in game'
                            ) {
                                tempChest[payload.schema.schema_name][
                                    payload.template.template_id
                                ]['isOpenable'] = true;
                            } else
                                tempChest[payload.schema.schema_name][
                                    payload.template.template_id
                                ]['isOpenLink'] = true;
                        }
                        if (
                            payload.data.type &&
                            payload.data.type !== 'Random'
                        ) {
                            tempChest[payload.schema.schema_name][
                                payload.template.template_id
                            ]['isWearable'] = true;
                        }
                        if (payload.schema.schema_name === 'farmanimals') {
                            if (!(payload.data.sex === 'Random')) {
                                tempChest[payload.schema.schema_name][
                                    payload.template.template_id
                                ]['isWearable'] = true;
                            } else {
                                tempChest[payload.schema.schema_name][
                                    payload.template.template_id
                                ]['isOpenLink'] = true;
                            }
                        }
                        if (payload.schema.schema_name === 'memberships') {
                            if (payload.data.type === 'Random') {
                                tempChest[payload.schema.schema_name][
                                    payload.template.template_id
                                ]['isOpenLink'] = true;
                            }
                        }
                    }
                    if (
                        !tempChest[payload.schema.schema_name][
                            payload.template.template_id
                        ]['idList']
                    ) {
                        tempChest[payload.schema.schema_name][
                            payload.template.template_id
                        ]['idList'] = [];
                    }
                    tempChest[payload.schema.schema_name][
                        payload.template.template_id
                    ]['idList'].push(payload.asset_id);
                    if (!tempSchema.includes(payload.schema.schema_name))
                        tempSchema.push(payload.schema.schema_name);
                });
                tempSchema.forEach((name) => {
                    state[name] = [];
                    const templateIds = Object.keys(tempChest[name]);
                    templateIds.forEach((template, i) => {
                        state[name].push(tempChest[name][template]);
                        state[name][i]['total'] =
                            tempChest[name][template]['idList'].length;
                    });
                });
                state.refundChest['idList'] = tempRefund;
                state.refundChest['total'] = tempRefund.length;
                state.status = 'loaded';
            })
            .addCase(getAtomicChest.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(getRefundItem.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getRefundItem.fulfilled, (state, action) => {
                if (action.payload[0]) {
                    state.refundConf = action.payload[0].asset_ids;
                    state.refundAmount = action.payload[0].reward;
                }
                state.status = 'loaded';
            })
            .addCase(getRefundItem.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        // .addCase(getExchangeTransactions.pending, (state, action) => {
        //     state.status = 'loading';
        // })
        // .addCase(getExchangeTransactions.fulfilled, (state, action) => {
        //     state.status = 'loaded';
        // })
        // .addCase(getExchangeTransactions.rejected, (state, action) => {
        //     state.status = 'failed';
        //     state.error = action.error.message;
        // });
    }
});
export const { popAssets } = atomicSlice.actions;

export default atomicSlice.reducer;
